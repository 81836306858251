<template>
  <div class="access-tab">
    <h5 class="card-title">App Shortcuts</h5>
    <p class="text-muted mb-1">The app shortcuts visible to the user.</p>
    <ul class="text-muted mb-4">
      <li>
        A user gains access to an app shortcut if they have access to at least one building with the app shortcut.
      </li>
    </ul>
    <phillip-table
      table="app-shortcut"
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      hide-filters
      empty-text="The user has no app shortcuts."
      :filters="{ 'user.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'user-detail-app-shortcuts-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      appShortcut: null,
    };
  },
};
</script>
