import home from './home';
import Area from '../views/area/Area.vue';
import NewArea from '../views/area/NewArea.vue';
import AreaDetail from '../views/area/AreaDetail.vue';

const area = {
  path: '/area',
  name: 'Zones',
  component: Area,
  meta: { parent: home },
};

const newArea = {
  path: '/area/new',
  name: 'New Zone',
  component: NewArea,
  meta: { parent: area },
};

const areaDetail = {
  path: '/area/detail/:pk',
  name: 'Zone Detail',
  redirect: '/area/detail/:pk/area',
  component: AreaDetail,
  meta: { parent: area, noLink: true },
};

// Tabs
import AreaTab from '../views/area/tabs/AreaTab.vue';
import UsersTab from '../views/area/tabs/UsersTab.vue';
import UserGroupsTab from '../views/area/tabs/UserGroupsTab.vue';
import SipDoorsTab from '../views/area/tabs/SipDoorsTab.vue';
import ControlledEntriesTab from '../views/area/tabs/ControlledEntriesTab.vue';
import CamerasTab from '../views/area/tabs/CamerasTab.vue';
import ChastiaDevicesTab from '../views/area/tabs/ChastiaDevicesTab.vue';

areaDetail.children = [
  {
    path: '/area/detail/:pk/area',
    name: 'Zone',
    component: AreaTab,
    meta: { parent: areaDetail },
  },
  {
    path: '/area/detail/:pk/users',
    name: 'Users',
    component: UsersTab,
    meta: { parent: areaDetail },
  },
  {
    path: '/area/detail/:pk/user-groups',
    name: 'Groups',
    component: UserGroupsTab,
    meta: { parent: areaDetail },
  },
  {
    path: '/area/detail/:pk/sip-doors',
    name: 'SIP Doors',
    component: SipDoorsTab,
    meta: { parent: areaDetail },
  },
  {
    path: '/area/detail/:pk/controlled-entries',
    name: 'Controlled Entries',
    component: ControlledEntriesTab,
    meta: { parent: areaDetail },
  },
  {
    path: '/area/detail/:pk/cameras',
    name: 'Cameras',
    component: CamerasTab,
    meta: { parent: areaDetail },
  },
  {
    path: '/area/detail/:pk/chastia-devices',
    name: 'Chastia Devices',
    component: ChastiaDevicesTab,
    meta: { parent: areaDetail },
  },
];

const areaRoutes = [area, newArea, areaDetail];

export default areaRoutes;
