import DataStorageFactory from '../abstract/data-storage-factory.js';
import AccessToken from './access-token-class';

/** @extends DataStorageFactory<AccessToken> */
class AccessTokenFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'token';
  getModelName = () => 'access-token';
  getPrettyName = () => 'Access Token';
}

const accessTokenFactory = new AccessTokenFactory(AccessToken.prototype);

export default accessTokenFactory;
