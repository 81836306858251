<template>
  <card title="All articles" description="The list of all articles.">
    <new-article-button slot="title-button" class="ml-3" />
    <phillip-table hide-new-button table="article" />
  </card>
</template>

<script>
import NewArticleButton from '../../components/buttons/NewArticleButton';

export default {
  name: 'page-articles',
  components: {
    NewArticleButton,
  },
};
</script>
