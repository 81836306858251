<template>
  <card class="page-controlled-entry" title="All controlled entries" description="The list of all controlled entries.">
    <new-controlled-entry-button slot="title-button" class="ml-3" />
    <phillip-table hide-new-button table="controlled-entry" />
  </card>
</template>

<script>
import NewControlledEntryButton from '../../components/buttons/NewControlledEntryButton';

export default {
  name: 'page-controlled-entries',
  components: {
    NewControlledEntryButton,
  },
};
</script>
