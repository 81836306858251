<template>
  <card
    class="short page-new-area"
    title="New zone"
    description="The general info about the zone. More settings will be available after the zone is created."
  >
    <phillip-form form="area" />
  </card>
</template>

<script>
import areaFactory from '../../model/area/area-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-area',
  methods: {
    ...mapMutations({
      setArea: 'area/form/setStorage',
    }),
  },
  created() {
    this.setArea(areaFactory.blank());
  },
  beforeDestroy() {
    this.setArea(null);
  },
};
</script>
