<template>
  <form class="form" @submit.prevent="nextStep">
    <p class="text-muted mb-4">Please, enter your current password.</p>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">
        Current password
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <div class="col-sm-9">
        <input required type="password" class="form-control" v-model="password" />
      </div>
    </div>
    <button type="submit" class="btn btn-primary btn-sm text-white">Submit</button>
  </form>
</template>

<script>
import { mapMutations } from 'vuex';
import connector from '../../../api/connector';

export default {
  name: 'change-password-step-1',
  data() {
    return {
      password: '',
    };
  },
  methods: {
    ...mapMutations({
      setToken: 'changePassword/setToken',
      resetToken: 'changePassword/resetToken',
    }),
    async nextStep() {
      connector
        .post('/change-password/request', { password: this.password })
        .then(({ data }) => {
          this.setToken(data.token);
          this.$router.push('/change-password/step/2');
        })
        .catch((e) => this.$modal.alert(e));
    },
  },
  created() {
    this.resetToken();
  },
};
</script>
