<template>
  <div class="camera-tab">
    <h5 class="card-title">Camera</h5>
    <p class="text-muted mb-4">The general info about the camera.</p>
    <phillip-form form="camera" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import cameraFactory from '../../../model/camera/camera-factory';

export default {
  name: 'camera-detail-camera-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setCamera: 'camera/form/setStorage',
    }),
  },
  created() {
    cameraFactory
      .pk(this.pk)
      .then((camera) => this.setCamera(camera))
      .catch((err) => this.$modal.alert(err));
  },
};
</script>
