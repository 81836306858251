/** @typedef {import('./data-storage').default} DataStorage */

import connector from '../../api/connector';

/**
 * Abstract class used as a base for every factory class
 *
 * DataStorageFactory is responsible for
 *  - creating DataStorage instances
 *  - fetching data from API
 *  - updating the API
 *
 * The DataStorage template parameter must be a class which inherits from
 * the DataStorage class
 *
 * @template StorageType
 */
class DataStorageFactory {
  /**
   * Returns the name of the primary key.
   *
   * This method SHOULD be overridden.
   *
   * @returns {string}
   */
  getPrimaryKey() {
    return 'id';
  }

  /**
   * Returns the name of the title key. Title key is the key which will be
   * displayed first in the data table component.
   *
   * This method SHOULD be overridden.
   *
   * @returns {string}
   */
  getTitleKey() {
    return this.getPrimaryKey();
  }

  /**
   * Returns the name of the model.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  getModelName() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the pretty name of the model.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  getPrettyName() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the singular name of the type.
   *
   * This method SHOULD be overridden.
   *
   * @returns {string}
   */
  getSingular() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the plural name of the type.
   *
   * This method SHOULD be overridden.
   *
   * @returns {string}
   */
  getPlural() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the name of the icon used by the model.
   *
   * This method SHOULD be overridden.
   *
   * @returns {string}
   */
  getIcon() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the prototype of type DataStorage used by this factory.
   *
   * This method MUST be overridden.
   *
   * @returns {StorageType}
   */
  create() {
    throw new Error('Not implemented');
  }

  /**
   * Initiates the record turning it into an instance of DataStorage
   *
   * @param {Object} record
   *
   * @returns {StorageType}
   */
  _init(record) {
    return new this.storagePrototype.constructor(record, this);
  }

  /**
   * Initiates multiple records turning it into an instance of DataStorage
   *
   * @param {Array<Object>}
   *
   * @returns {Array<StorageType>}
   */
  _initMultiple(records) {
    return records.map((record) => this._init(record));
  }

  /**
   * Fetches all records
   *
   * @param {Record<string,string|number>} filters
   * @param {string[]} connections
   *
   * @returns {Promise<Array<StorageType>>}
   */
  async fetch(filters = {}, connections = []) {
    const params = {};

    Object.entries(filters).forEach(([key, value]) => {
      params[`filters[${key}]`] = value;
    });

    connections.forEach((connection) => {
      params['connections[]'] = connection;
    });

    const { data } = await connector.get(`model/${this.getModelName()}`, { params });

    if (!Array.isArray(data)) {
      return [];
    }

    return this._initMultiple(data);
  }

  /**
   * Fetches all key value pairs
   *
   * @param {Object.<string, string|number>} filters
   *
   * @returns {Promise<Array<[string|number, string]>>}
   */
  async keyValuePairs(filters = {}) {
    const params = {};
    Object.entries(filters).forEach(([key, value]) => {
      params[`filters[${key}]`] = value;
    });

    const { data } = await connector.get(`model/${this.getModelName()}/key-value-pairs`, {
      params,
    });

    if (!Array.isArray(data)) {
      return [];
    }

    return data;
  }

  /**
   * Fetches a single record specified by primary key
   *
   * @param {number|string}
   *
   * @returns {Promise<StorageType>}
   */
  async pk(pk) {
    const { data } = await connector.get(`model/${this.getModelName()}/detail/${pk}`);
    return this._init(data);
  }

  /**
   * Fetches multiple records specified by their primary keys
   *
   * @param {Array<number|string>} pks
   *
   * @returns {Promise<Array<StorageType>>}
   */
  pkMultiple(pks) {
    return this.fetch({ 'pk.in': pks.join(',') });
  }

  /**
   * Saves the specified data storage to db
   *
   * @param {DataStorage} dataStorage
   */
  async save(dataStorage) {
    const name = this.getModelName();
    const pk = this.getPrimaryKey();
    const payload = dataStorage.apiPayload();

    if (dataStorage[pk] === undefined) {
      const { data } = await connector.post(`model/${name}/new`, payload);
      dataStorage[pk] = data[pk]; // New pk was generated
    } else {
      await connector.put(`model/${name}/${dataStorage[pk]}/update`, payload);
    }

    this.signalChange();
  }

  /**
   * Deletes the specified data storage from db
   *
   * @param {DataStorage|String|Number} dataStorageOrPk
   */
  async delete(dataStorageOrPk) {
    // todo: handle DataStorage
    const pk = dataStorageOrPk;
    await connector.post(`model/${this.getModelName()}/${pk}/delete`);

    this.signalChange();
  }

  /**
   * Creates a new blank instance of StorageType
   *
   * @param {PropertiesType} params
   *
   * @returns {StorageType}
   */
  blank(params = {}) {
    return this._init(params);
  }

  /**
   * Returns an empty instance of StorageType with the specified primary key.
   * Such object can be used to access the methods of the StorageType instance
   * which require only the primary key.
   *
   * Object created by this function should NEVER be saved.
   *
   * @param {string|number} pk
   *
   * @returns {StorageType}
   */
  pkMock(pk) {
    return Object.freeze(this.blank({ [this.getPrimaryKey()]: pk }));
  }

  /**
   * Signals that the data managed by this factory has changed
   */
  signalChange() {
    this.lastChange = Date.now();
    window.dispatchEvent(new CustomEvent('dataStorageFactory.signalChange'));
  }

  /**
   * Creates a new instance of DataStorageFactory
   */
  constructor(storagePrototype) {
    /**
     * The timestamp of the last data modification
     *
     * @type {number}
     */
    this.lastChange = null;

    /**
     * The prototype of the StorageType
     *
     * @type {StorageType}
     */
    this.storagePrototype = storagePrototype;
  }
}

export default DataStorageFactory;
