import home from './home';
import Camera from '../views/camera/Camera.vue';
import NewCamera from '../views/camera/NewCamera.vue';
import CameraDetail from '../views/camera/CameraDetail.vue';

const camera = {
  path: '/camera',
  name: 'Cameras',
  component: Camera,
  meta: { parent: home },
};

const newCamera = {
  path: '/camera/new',
  name: 'New Camera',
  component: NewCamera,
  meta: { parent: camera },
};

const cameraDetail = {
  path: '/camera/detail/:pk',
  name: 'Camera Detail',
  redirect: '/camera/detail/:pk/camera',
  component: CameraDetail,
  meta: { parent: camera, noLink: true },
};

// Tabs
import CameraTab from '../views/camera/tabs/CameraTab.vue';
import LocationTab from '../views/camera/tabs/LocationTab.vue';
import WhitelistTab from '../views/camera/tabs/WhitelistTab.vue';
import BlacklistTab from '../views/camera/tabs/BlacklistTab.vue';
import UserAccessTab from '../views/camera/tabs/UserAccessTab.vue';
import UserGroupAccessTab from '../views/camera/tabs/UserGroupAccessTab.vue';
import SnapshotTab from '../views/camera/tabs/SnapshotTab.vue';

cameraDetail.children = [
  {
    path: '/camera/detail/:pk/camera',
    name: 'Camera',
    component: CameraTab,
    meta: { parent: cameraDetail },
  },
  {
    path: '/camera/detail/:pk/location',
    name: 'Zone',
    component: LocationTab,
    meta: { parent: cameraDetail },
  },
  {
    path: '/camera/detail/:pk/whitelist',
    name: 'Whitelist',
    component: WhitelistTab,
    meta: { parent: cameraDetail },
  },
  {
    path: '/camera/detail/:pk/blacklist',
    name: 'Blacklist',
    component: BlacklistTab,
    meta: { parent: cameraDetail },
  },
  {
    path: '/camera/detail/:pk/user-access',
    name: 'Users',
    component: UserAccessTab,
    meta: { parent: cameraDetail },
  },
  {
    path: '/camera/detail/:pk/user-group-access',
    name: 'Groups',
    component: UserGroupAccessTab,
    meta: { parent: cameraDetail },
  },
  {
    path: '/camera/detail/:pk/snapshot',
    name: 'Snapshot',
    component: SnapshotTab,
    meta: { parent: cameraDetail },
  },
];

const cameraRoutes = [camera, newCamera, cameraDetail];

export default cameraRoutes;
