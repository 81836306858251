<template>
  <div class="whitelist-tab">
    <h5 class="card-title">Whitelist</h5>
    <p class="text-muted mb-4">The list of groups which are granted access to the SIP door.</p>
    <whitelist-form :storage="sipDoorFactory.pkMock(pk)" table-filter-name="sipDoorWhitelist.eq" />
  </div>
</template>

<script>
import sipDoorFactory from '../../../model/sip-door/sip-door-factory';
import WhitelistForm from '../../../components/forms/WhitelistForm.vue';

export default {
  name: 'sip-door-detail-whitelist-tab',
  components: {
    WhitelistForm,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      sipDoorFactory,
    };
  },
};
</script>
