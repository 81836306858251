<template>
  <form :id="formId" @submit.prevent="onSubmit" class="model-form">
    <div v-for="[groupName, inputs] in groups" :key="groupName">
      <h5 v-if="groupName">{{ groupName }}</h5>

      <template v-for="(cfg, key) in inputs">
        <template v-if="shouldDisplayInput(cfg)">
          <div :key="key" class="form-group row">
            <!-- Label -->
            <label class="col-sm-2 col-form-label">
              {{ cfg.label }}
              <abbr v-if="cfg.required" class="text-danger" title="This field is required">*</abbr>
            </label>

            <!-- Input -->
            <div class="col-sm-10" :style="{ maxWidth: cfg.type === 'tiny-mce' ? '' : '600px' }">
              <template v-if="cfg.type === 'tiny-mce'">
                <tiny-mce
                  :api-key="TINY_MCE_KEY"
                  v-model="storage[key]"
                  :init="{
                    plugins:
                      'autolink autoresize autosave charmap emoticons help image insertdatetime link lists media preview quickbars searchreplace table visualblocks visualchars wordcount',
                    toolbar:
                      'formatselect | bold italic underline strikethrough link | alignleft aligncenter alignright | bullist numlist | quickimage media table | emoticons',
                    quickbars_insert_toolbar: false,
                    toolbar_sticky: true,
                    toolbar_sticky_offset: 60,
                  }"
                />
              </template>
              <template v-else>
                <component
                  :is="cfg.type"
                  v-bind="{ ...cfg, type: undefined, readonly: isReadonly(cfg) }"
                  v-model="storage[key]"
                />
              </template>
            </div>
          </div>
        </template>
      </template>
    </div>

    <code v-if="session.debug">
      <pre>{{ storage }}</pre>
    </code>

    <!-- Submit -->
    <button
      v-if="!hideSubmitButton"
      :disabled="loading"
      type="submit"
      :class="['btn', 'btn-sm', 'submit', 'text-white', success ? 'btn-success' : 'btn-primary']"
    >
      <template v-if="loading">
        Saving...
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </template>
      <template v-else-if="success">Saved successfully!</template>
      <template v-else>Save</template>
    </button>
  </form>
</template>

<script>
import { nanoid } from 'nanoid/non-secure';
import DataStorage from '../../model/abstract/data-storage';
import TinyMce from '@tinymce/tinymce-vue';
import Checkbox from '../inputs/Checkbox';
import DateInput from '../inputs/DateInput';
import EmailInput from '../inputs/EmailInput';
import NumberInput from '../inputs/NumberInput';
import PasswordInput from '../inputs/PasswordInput';
import SelectInput from '../inputs/SelectInput';
import TelInput from '../inputs/TelInput';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import UrlInput from '../inputs/UrlInput';
import session from '../../api/session';
import { TINY_MCE_KEY } from '../../environment';

export default {
  name: 'model-form',
  components: {
    TinyMce,
    Checkbox,
    DateInput,
    EmailInput,
    NumberInput,
    PasswordInput,
    SelectInput,
    TelInput,
    TextareaInput,
    TextInput,
    UrlInput,
  },
  props: {
    formId: {
      type: String,
      default: () => nanoid(),
    },
    storage: {
      type: DataStorage,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    hideSubmitButton: {
      type: Boolean,
      default: false,
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TINY_MCE_KEY,
      loading: false,
      success: undefined,
      session,
    };
  },
  computed: {
    groups() {
      const groupMap = Object.entries(this.config).reduce((acc, [key, cfg]) => {
        const section = cfg.section || '';

        if (!acc[section]) {
          acc[section] = {};
        }

        acc[section][key] = cfg;

        return acc;
      }, {});

      return Object.entries(groupMap);
    },
  },
  methods: {
    shouldDisplayInput(cfg) {
      if (cfg.ignore) {
        return false;
      }

      if (cfg.onlyIfNew && !this.storage.isNew) {
        return false;
      }

      if (cfg.onlyIfEdit && this.storage.isNew) {
        return false;
      }

      const hideIf = cfg.hideIf || [];

      for (const { prop, value } of hideIf) {
        if (this.storage[prop] === value || this.storage[prop] === undefined) {
          return false;
        }
      }

      return true;
    },
    isReadonly(cfg) {
      return cfg.readonly || (cfg.readonlyIfEdit && !this.storage.isNew);
    },
    async onSubmit() {
      if (this.loading) {
        return; // Prevent multiple submits
      }

      this.success = false;
      this.loading = true;

      try {
        const wasNew = this.storage.isNew;

        await this.storage.save();

        if (wasNew && !this.noRedirect) {
          const model = this.storage._factory.getModelName();
          const pk = this.storage._factory.getPrimaryKey();

          this.$router.push(`/${model}/detail/${this.storage[pk]}`);
        }

        this.success = true;

        setTimeout(() => {
          this.success = undefined;
        }, 3000);

        this.$emit('success');
      } catch (err) {
        console.log(err);
        alert(err);
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    Object.entries(this.config).forEach(([key, cfg]) => {
      const defaultSpecified = Object.prototype.hasOwnProperty.call(cfg, 'default');
      const valueMissing = !Object.prototype.hasOwnProperty.call(this.storage, key);

      if (defaultSpecified && valueMissing) {
        this.storage[key] = cfg.default();
      }
    });
  },
};
</script>
