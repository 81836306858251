<template>
  <component :is="`${select}-tag-select`" v-bind="$attrs" v-model="selected" />
</template>

<script>
import AccessTokenTagSelect from './AccessTokenTagSelect';
import AppShortcutTagSelect from './AppShortcutTagSelect';
import AreaTagSelect from './AreaTagSelect';
import ArticleTagSelect from './ArticleTagSelect';
import BillingAddressTagSelect from './BillingAddressTagSelect';
import BuildingTagSelect from './BuildingTagSelect';
import CameraTagSelect from './CameraTagSelect';
import ChastiaDeviceTagSelect from './ChastiaDeviceTagSelect';
import ChastiaDeviceMeterTypeTagSelect from './ChastiaDeviceMeterTypeTagSelect';
import ChastiaFunctionTagSelect from './ChastiaFunctionTagSelect';
import ChastiaSensorTagSelect from './ChastiaSensorTagSelect';
import ChastiaSensorMeasurementTagSelect from './ChastiaSensorMeasurementTagSelect';
import ChastiaSensorMeasuringTypeTagSelect from './ChastiaSensorMeasuringTypeTagSelect';
import ControlledEntryTagSelect from './ControlledEntryTagSelect';
import DoorBellTagSelect from './DoorBellTagSelect';
import EmailMessageTagSelect from './EmailMessageTagSelect';
import FaqTagSelect from './FaqTagSelect';
import FirebaseMessageTagSelect from './FirebaseMessageTagSelect';
import IosVoipMessageTagSelect from './IosVoipMessageTagSelect';
import LicensePlateTagSelect from './LicensePlateTagSelect';
import RfidTagSelect from './RfidTagSelect';
import RfidOwnerChangelogTagSelect from './RfidOwnerChangelogTagSelect';
import SessionTagSelect from './SessionTagSelect';
import SipDoorTagSelect from './SipDoorTagSelect';
import SipDoorActionTagSelect from './SipDoorActionTagSelect';
import UserTagSelect from './UserTagSelect';
import UserGroupTagSelect from './UserGroupTagSelect';
import UserSipAccountTagSelect from './UserSipAccountTagSelect';

export default {
  name: 'phillip-tag-select',
  components: {
    AccessTokenTagSelect,
    AppShortcutTagSelect,
    AreaTagSelect,
    ArticleTagSelect,
    BillingAddressTagSelect,
    BuildingTagSelect,
    CameraTagSelect,
    ChastiaDeviceTagSelect,
    ChastiaDeviceMeterTypeTagSelect,
    ChastiaFunctionTagSelect,
    ChastiaSensorTagSelect,
    ChastiaSensorMeasurementTagSelect,
    ChastiaSensorMeasuringTypeTagSelect,
    ControlledEntryTagSelect,
    DoorBellTagSelect,
    EmailMessageTagSelect,
    FaqTagSelect,
    FirebaseMessageTagSelect,
    IosVoipMessageTagSelect,
    LicensePlateTagSelect,
    RfidTagSelect,
    RfidOwnerChangelogTagSelect,
    SessionTagSelect,
    SipDoorTagSelect,
    SipDoorActionTagSelect,
    UserTagSelect,
    UserGroupTagSelect,
    UserSipAccountTagSelect,
  },
  props: {
    select: {
      type: String,
      required: true,
    },
    value: {},
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.selected = newValue;
      }
    },
    selected() {
      this.$emit('input', this.selected);
    },
  },
};
</script>
