<template>
  <card class="short page-area-detail">
    <!-- Problems -->
    <div v-if="hasProblems" class="alert alert-warning d-flex align-items-center">
      <icon icon="feather-alert-circle" />
      <div class="ml-3">
        <p v-if="problems.groups">No group can access the zone.</p>
        <p v-if="problems.devices">There are no devices in the zone.</p>
      </div>
    </div>

    <!-- Navigation -->
    <nav class="card-nav mb-4" role="group">
      <router-link :to="t('area')">Zone</router-link>
      <div class="spacer"></div>
      <router-link :to="t('user-groups')">Groups</router-link>
      <router-link :to="t('users')">Users</router-link>
      <div class="spacer"></div>
      <router-link :to="t('sip-doors')">SIP Doors</router-link>
      <router-link :to="t('controlled-entries')">Controlled Entries</router-link>
      <router-link :to="t('cameras')">Cameras</router-link>
      <router-link :to="t('chastia-devices')">Chastia Devices</router-link>
    </nav>

    <!-- Tab content -->
    <main class="pt-2">
      <router-view />
    </main>
  </card>
</template>

<script>
import areaFactory from '../../model/area/area-factory';

export default {
  name: 'page-area-detail',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    hasProblems() {
      return Object.values(this.problems).some(Boolean);
    },
  },
  data() {
    return {
      problems: {},
      submitHandler: null,
    };
  },
  methods: {
    t(path) {
      return `/area/detail/${this.pk}/${path}`;
    },
    async fetchProblems() {
      this.problems = await areaFactory.pkMock(this.pk).computeProblems();
    },
  },
  created() {
    // todo: do this better
    this.submitHandler = () => this.fetchProblems();
    window.addEventListener('dataStorageFactory.signalChange', this.submitHandler);
    this.fetchProblems();
  },
  beforeDestroy() {
    window.removeEventListener('dataStorageFactory.signalChange', this.submitHandler);
  },
};
</script>
