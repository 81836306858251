<template>
  <div class="row w-100 mx-0 auth-page">
    <div class="col-md-6 col-xl-4 mx-auto">
      <div class="card mb">
        <div class="row">
          <div class="col-md-12 pl-md-0">
            <div class="auth-form-wrapper px-4 py-5">
              <a href="#" class="noble-ui-logo d-block mb-2">Phillip <span>Admin</span></a>
              <h5 class="text-muted font-weight-normal mb-4">Welcome back! Log in to your account.</h5>
              <form class="forms-sample" @submit.prevent="onSubmit">
                <div class="form-group mb-3">
                  <label for="email">Email address</label>
                  <input
                    v-model="email"
                    required
                    type="email"
                    :class="['form-control', invalidEmail ? 'is-invalid' : '']"
                    id="email"
                    placeholder="Email"
                  />
                  <p v-if="invalidEmail" class="text-danger mt-1">Invalid email address</p>
                </div>
                <div class="form-group mb-3">
                  <label for="password">Password</label>
                  <input
                    v-model="password"
                    required
                    type="password"
                    :class="['form-control', invalidPassword ? 'is-invalid' : '']"
                    id="password"
                    autocomplete="current-password"
                    placeholder="Password"
                  />
                  <p v-if="invalidPassword" class="text-danger mt-1">Invalid password</p>
                </div>
                <div class="form-group">
                  <label for="password">2FA Code (If enabled)</label>
                  <input
                    type="text"
                    :class="['form-control', invalidTotp ? 'is-invalid' : '']"
                    id="totp"
                    placeholder="Code"
                    v-model="totp"
                    pattern="\d{6}"
                    minlength="6"
                    maxlength="6"
                  />
                  <p v-if="invalidTotp && totp" class="text-danger mt-1">Invalid 2FA code</p>
                  <p v-if="invalidTotp && !totp" class="text-danger mt-1">2FA code is required</p>
                </div>
                <div class="mt-4 d-flex justify-content-start align-items-center">
                  <button class="btn btn-sm btn-primary mr-2 mb-2 mb-md-0 text-white">Login</button>
                  <router-link to="/reset-password" class="text-muted ml-4"> Reset password </router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import session from '../api/session';

const OBJECT_DOES_NOT_EXIST_ERROR_CODE = 4300;
const INVALID_PASSWORD_ERROR_CODE = 4301;
const INVALID_TOTP_ERROR_CODE = 4313;

export default {
  name: 'page-login',
  data() {
    return {
      email: '',
      password: '',
      totp: '',
      invalidEmail: false,
      invalidPassword: false,
      invalidTotp: false,
    };
  },
  methods: {
    onSubmit() {
      session.login(this.email, this.password, this.totp).catch((e) => {
        const code = e.response?.data?.code;

        switch (code) {
          case INVALID_TOTP_ERROR_CODE:
            this.invalidTotp = true;
            break;

          case OBJECT_DOES_NOT_EXIST_ERROR_CODE:
            this.invalidEmail = true;
            break;

          case INVALID_PASSWORD_ERROR_CODE:
            this.invalidPassword = true;
            break;

          default:
            this.$modal.alert(e);
            break;
        }
      });
    },
  },
  watch: {
    email() {
      this.invalidEmail = false;
    },
    password() {
      this.invalidPassword = false;
    },
    totp() {
      this.invalidTotp = false;
    },
  },
};
</script>
