/** @generated */

/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/generate-environment.js. If you wish to
 * change the environment variables, modify the file config/environment.yaml and then run:
 *
 *     npm run generate:environment
 *
 * -------------------------------------------------------------------------------------------------
 */

import getenv from './utils/getenv';

/**
 * Whether the feature is on.
 */
function isFeatureOn(feature) {
  return feature === 'on';
}

/**
 * The base URL of the API.
 */
export const API_BASE =
  process.env.REACT_APP_API_BASE ||
  getenv('API_BASE', {
    development: '/api/v1',
    test: '/api/v1',
    production: '/api/v1',
  });

/**
 * The base URL of the Chastia 3rd party API.
 */
export const CHASTIA_API_BASE =
  process.env.REACT_APP_CHASTIA_API_BASE ||
  getenv('CHASTIA_API_BASE', {
    development: 'https://em-test.eiot.sk/api/public/IoT/1/',
    test: 'https://em-test.eiot.sk/api/public/IoT/1/',
    production: 'https://em-test.eiot.sk/api/public/IoT/1/',
  });

/**
 * The TinyMCE API key.
 */
export const TINY_MCE_KEY =
  process.env.REACT_APP_TINY_MCE_KEY ||
  getenv('TINY_MCE_KEY', {
    development: 'm3c601tl1o52eadw7zk0qnternukpyjj2egl9mhsetha5vsr',
    test: 'm3c601tl1o52eadw7zk0qnternukpyjj2egl9mhsetha5vsr',
    production: 'm3c601tl1o52eadw7zk0qnternukpyjj2egl9mhsetha5vsr',
  });

/**
 * Whether 2FA is required.
 */
export const REQUIRE_2FA =
  process.env.REACT_APP_REQUIRE_2FA ||
  getenv('REQUIRE_2FA', {
    development: false,
    test: false,
    production: 'yes',
  });
