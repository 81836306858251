<template>
  <div class="access-tab">
    <h5 class="card-title">App Shortcuts</h5>
    <p class="text-muted mb-4">The app shortcuts visible to the users in the building.</p>
    <phillip-table
      table="app-shortcut"
      hide-new-button
      hide-edit-button
      delete-button-label="Remove app shortcut"
      hide-filters
      empty-text="The building has no app shortcuts."
      :filters="{ 'building.eq': pk }"
      :on-delete="removeAppShortcut"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="app-shortcut"
        placeholder="Add app shortcut to building"
        v-model="appShortcut"
      />
      <button @click="addAppShortcut" :disabled="appShortcut === null" class="btn btn-sm btn-primary text-white ml-3">
        Add app shortcut
      </button>
    </div>
  </div>
</template>

<script>
import buildingFactory from '../../../model/building/building-factory';
import appShortcutFactory from '../../../model/app-shortcut/app-shortcut-factory';

export default {
  name: 'building-detail-app-shortcuts-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      appShortcut: null,
    };
  },
  methods: {
    addAppShortcut() {
      if (this.appShortcut !== null) {
        buildingFactory
          .pkMock(this.pk)
          .addAppShortcut(this.appShortcut)
          .then(() => appShortcutFactory.signalChange())
          .catch((e) => this.$modal.alert(e));
      }
    },
    removeAppShortcut(appShortcutId) {
      this.$modal
        .confirm(`Remove app shortcut #${appShortcutId} from building?`)
        .then(() => {
          buildingFactory
            .pkMock(this.pk)
            .deleteAppShortcut(appShortcutId)
            .then(() => appShortcutFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
