<template>
  <div class="controlled-entries-tab">
    <h5 class="card-title">Controlled Entries</h5>
    <p class="text-muted mb-1">The list of controlled entries accessible by the group.</p>
    <ul class="text-muted mb-4">
      <li>
        By default, a group can access a controlled entry if they have access to the
        <abbr title="See the 'Zone' tab of a controlled entry for more info">zone</abbr> of the controlled entry.
      </li>
      <li>
        A group can be
        <abbr title="See the 'Blacklist' tab of a controlled entry for more info">blacklisted</abbr>
        from accessing a controlled entry which is in an accessible zone.
      </li>
      <li>
        A group can be
        <abbr title="See the 'Whitelist' of a controlled entry tab for more info">whitelisted</abbr>
        to access a controlled entry which is in an inaccessible zone.
      </li>
    </ul>
    <phillip-table
      table="controlled-entry"
      hide-new-button
      hide-filters
      hide-delete-button
      edit-button-label="Detail"
      empty-text="The group can access no controlled entries."
      :filters="{ 'userGroupAccess.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'user-group-detail-controlled-entries-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
