import ResetPassword from '../views/reset-password/ResetPassword.vue';

import Step1 from '../views/reset-password/steps/Step1.vue';
import Step2 from '../views/reset-password/steps/Step2.vue';
import Step3 from '../views/reset-password/steps/Step3.vue';

const resetPassword = {
  path: '/reset-password',
  name: 'Reset password',
  component: ResetPassword,
  redirect: '/reset-password/step/1',
  meta: { noLink: true },
};

resetPassword.children = [
  {
    path: '/reset-password/step/1',
    name: 'Step one',
    component: Step1,
    meta: { parent: resetPassword },
  },
  {
    path: '/reset-password/step/2',
    name: 'Step two',
    component: Step2,
    meta: { parent: resetPassword },
  },
  {
    path: '/reset-password/step/3',
    name: 'Step three',
    component: Step3,
    meta: { parent: resetPassword },
  },
];

export default resetPassword;
