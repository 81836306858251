<template>
  <div class="billing-tab">
    <h5 class="card-title">Billing</h5>
    <p class="text-muted mb-4">The billing address of the user.</p>
    <phillip-form no-redirect form="billing-address" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import billingAddressFactory from '../../../model/billing-address/billing-address-factory';

export default {
  name: 'user-detail-billing-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setBillingAddress: 'billingAddress/form/setStorage',
    }),
  },
  created() {
    billingAddressFactory
      .forUser(this.pk)
      .then((billingAddress) => this.setBillingAddress(billingAddress))
      .catch((err) => this.$modal.alert(err));
  },
  beforeDestroy() {
    this.setBillingAddress(null);
  },
};
</script>
