<template>
  <area-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by building -->
      <div class="table-filter-row">
        <label for="user-table-filter-building" class="table-filter-label">
          Filter by building<br />
          <small class="text-muted">Multiple buildings can be selected.</small>
        </label>
        <phillip-select
          select="building"
          id="user-table-filter-building"
          placeholder="All buildings"
          v-model="buildings"
          multiple
        />
      </div>
    </template>
  </area-table-base>
</template>

<script>
import AreaTableBase from './AreaTableBase';

export default {
  name: 'area-table',
  components: {
    AreaTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.buildings.length > 0) {
        filters['buildingId.in'] = this.buildings.join(',');
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      this.buildings = [];
    },
  },
  data() {
    return {
      buildings: [],
    };
  },
};
</script>
