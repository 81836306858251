<template>
  <div class="chastia-sensor-tab">
    <h5 class="card-title">Chastia sensor</h5>
    <p class="text-muted mb-4">The general info about the Chastia sensor.</p>
    <p class="text-muted mb-4">
      Parent device:
      <phillip-lazy-display
        style="display: inline-block"
        display="chastia-device"
        :loading="chastiaSensor == null"
        :pk="(chastiaSensor || {}).parent"
      />
    </p>
    <phillip-form form="chastia-sensor" />
  </div>
</template>

<script>
import chastiaSensorFactory from '../../../../model/chastia-sensor/chastia-sensor-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'chastia-sensor-detail-chastia-sensor-tab',
  methods: {
    ...mapMutations({
      setChastiaSensor: 'chastiaSensor/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    chastiaSensorFactory
      .pk(this.pk)
      .then((chastiaSensor) => this.setChastiaSensor(chastiaSensor))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setChastiaSensor(null);
  },
};
</script>
