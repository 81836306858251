<template>
  <div class="modal phillip-modal fade show" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog" role="document" :style="modalDialogStyle">
      <div class="modal-content">
        <!-- Header -->
        <div class="modal-header">
          <slot name="header">
            <h5 class="modal-title">{{ heading }}</h5>
            <button type="button" class="btn close" aria-label="Close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </slot>
        </div>

        <!-- Body -->
        <div class="modal-body">
          <slot name="body"></slot>
        </div>

        <!-- Footer -->
        <div class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn btn-sm btn-secondary text-white" @click="close">
              {{ cancelButtonText }}
            </button>
            <button
              form="phillip-form-modal"
              type="submit"
              class="btn btn-sm btn-primary text-white"
              :form-id="formId"
              @click="confirm"
            >
              {{ confirmButtonText }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'form-modal',
  props: {
    heading: {
      type: String,
    },
    width: {
      type: Number,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    formId: {
      type: String,
    },
  },
  computed: {
    modalDialogStyle() {
      const style = {};

      if (this.width) {
        style.maxWidth = this.width + 'px';
      }

      return style;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss">
.modal.phillip-modal {
  display: block;

  padding-right: 12px;

  background-color: rgba(0, 0, 0, 0.5);
}

.modal.phillip-modal .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal.phillip-modal .modal-header .close {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
}
</style>
