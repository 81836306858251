import DataStorageFactory from '../abstract/data-storage-factory.js';
import ChastiaSensor from './chastia-sensor-class';

/** @extends DataStorageFactory<ChastiaSensor> */
class ChastiaSensorFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'chastiaName';
  getModelName = () => 'chastia-sensor';
  getPrettyName = () => 'Chastia Sensor';
  getSingular = () => 'Chastia sensor';
  getPlural = () => 'Chastia sensors';
  getIcon = () => 'feather-battery-charging';
}

const chastiaSensorFactory = new ChastiaSensorFactory(ChastiaSensor.prototype);

export default chastiaSensorFactory;
