<template>
  <div class="faq-tab">
    <h5 class="card-title">FAQ</h5>
    <p class="text-muted mb-4">The general info about the FAQ.</p>
    <phillip-form form="faq" />
  </div>
</template>

<script>
import faqFactory from '../../../model/faq/faq-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-faq',
  methods: {
    ...mapMutations({
      setFaq: 'faq/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    faqFactory
      .pk(this.pk)
      .then((faq) => this.setFaq(faq))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setFaq(null);
  },
};
</script>
