<template>
  <form class="forms-sample" @submit.prevent="nextStep">
    <p class="text-muted mb-4">
      Please, enter the validation code which you have received in an email sent to
      <u>{{ session.email }}</u
      >.
    </p>
    <div class="form-group mb-3">
      <label for="code">
        Validation code
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <input v-model="code" required type="text" class="form-control" id="code" placeholder="Code" />
    </div>
    <div class="mt-4 d-flex align-items-center">
      <button type="submit" class="btn btn-primary btn-sm text-white">Submit</button>
      <router-link to="/login" class="text-muted ml-4">Cancel</router-link>
    </div>
  </form>
</template>

<script>
import connector from '../../../api/connector';
import session from '../../../api/session';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'reset-password-step-1',
  computed: {
    ...mapGetters({
      token: 'resetPassword/getToken',
    }),
  },
  data() {
    return {
      session,
      code: '',
    };
  },
  methods: {
    ...mapMutations({
      setCode: 'resetPassword/setCode',
      resetCode: 'resetPassword/resetCode',
    }),
    nextStep() {
      connector
        .put('/access-token/validate', { token: this.token, code: this.code })
        .then(() => {
          this.setCode(this.code);
          this.$router.push('/reset-password/step/3');
        })
        .catch(() => this.$modal.alert('The code is invalid or expired'));
    },
  },
  created() {
    this.resetCode();

    if (!this.token) {
      this.$router.push('/reset-password/step/1');
    }
  },
};
</script>
