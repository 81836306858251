<template>
  <div v-if="results.length">
    <!-- Header -->
    <div class="d-flex align-items-center mt-3 mb-2">
      <h5 class="text-uppercase">{{ factory.getPlural() }}</h5>
      <p class="text-muted ml-2">
        Found {{ results.length }}
        <template v-if="results.length > 1">{{ factory.getPlural() }}</template>
        <template v-else>{{ factory.getSingular() }}</template>
      </p>
    </div>

    <!-- Results -->
    <router-link
      v-for="result in someResults"
      :key="result.pk()"
      :is="noLink ? 'span' : 'router-link'"
      class="d-flex"
      :to="`/${factory.getModelName()}/detail/${result.pk()}`"
    >
      <icon :icon="factory.getIcon()" />
      <span v-html="highlight(result)"></span>
    </router-link>
  </div>
</template>

<script>
import Mark from 'mark.js';
import DataStorageFactory from '../../../model/abstract/data-storage-factory';

export default {
  name: 'dashboard-layout-search-section-header',
  props: {
    q: { type: String, required: true },
    results: { type: Array, required: true },
    factory: { type: DataStorageFactory, required: true },
    noLink: { type: Boolean, default: false },
  },
  computed: {
    someResults() {
      return this.results.slice(0, Math.min(100, this.results.length)).map((result) => this.factory.blank(result));
    },
  },
  methods: {
    highlight(result) {
      const span = document.createElement('span');
      span.textContent = result.pretty();
      const mark = new Mark(span);

      mark.mark(this.q);

      return span.innerHTML;
    },
  },
};
</script>
