<template>
  <div class="measurements-tab">
    <h4 class="card-title">Measurements</h4>
    <p class="text-muted mb-1">The latest measurements taken by the sensor.</p>

    <phillip-table
      table="chastia-sensor-measurement"
      hide-new-button
      hide-edit-button
      hide-delete-button
      disable-detail
      :exclude-columns="['chastiaSensorId']"
      :filters="{ 'chastiaSensorId.eq': this.pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'chastia-sensor-detail-measurements-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
