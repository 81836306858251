<template>
  <div class="actions-tab">
    <h4 class="card-title">Actions</h4>
    <p class="text-muted mb-4">The actions of the SIP door.</p>
    <phillip-table
      table="sip-door-action"
      disable-detail
      hide-filters
      empty-text="The SIP door has no actions."
      :filters="{ 'sipDoorId.eq': this.pk }"
      :on-new="newSipDoorAction"
      :on-edit="editSipDoorAction"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import sipDoorActionFactory from '../../../model/sip-door-action/sip-door-action-factory';

export default {
  name: 'sip-door-detail-actions-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setSipDoorAction: 'sipDoorAction/form/setStorage',
    }),
    newSipDoorAction() {
      this.setSipDoorAction(sipDoorActionFactory.blank({ sipDoorId: this.pk }));
      this.$modal.openForm('sipDoorAction');
    },
    async editSipDoorAction(pk) {
      this.setSipDoorAction(await sipDoorActionFactory.pk(pk)); // todo: open loading modal
      this.$modal.openForm('sipDoorAction');
    },
  },
};
</script>
