<template>
  <card class="short page-ios-voip-message-detail">
    <!-- Navigation -->
    <nav class="card-nav mb-4" role="group">
      <router-link :to="t('ios-voip-message')">iOS VOIP Message</router-link>
    </nav>

    <!-- Tab content -->
    <main class="pt-2">
      <router-view />
    </main>
  </card>
</template>

<script>
export default {
  name: 'page-ios-voip-message-detail',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    t(path) {
      return `/ios-voip-message/detail/${this.pk}/${path}`;
    },
  },
};
</script>
