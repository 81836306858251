import LicensePlate from '../../model/license-plate/license-plate-class'; // eslint-disable-line

const state = {
  /**
   * @type {Array<LicensePlate>}
   */
  licensePlates: null,
};

const getters = {
  /**
   * @param {state}
   */
  getLicensePlates: ({ licensePlates }) => licensePlates,

  /**
   * Returns the CSV from the license plates
   *
   * @param {state}
   */
  getCSV: ({ licensePlates }) => licensePlates.map(({ country, code }) => `${country} ${code}`).join('\n'),
};

const actions = {};

const mutations = {
  /**
   * @param {state} state
   * @param {?Array<LicensePlate>} payload
   */
  setLicensePlates: (state, payload) => (state.licensePlates = payload),

  /**
   * @param {state} state
   * @param {LicensePlate} payload
   */
  addLicensePlate: (state, payload) => {
    // Try to remove the license plate first
    mutations.removeLicensePlate(state, payload);

    state.licensePlates.push(payload);
  },

  /**
   * @param {state} state
   * @param {LicensePlate} payload
   */
  removeLicensePlate: (state, payload) =>
    (state.licensePlates = state.licensePlates.filter((licensePlate) => licensePlate.id !== payload.id)),
};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
