<template>
  <div class="page-change-password">
    <card title="Change password" class="short">
      <router-view />
    </card>
  </div>
</template>

<script>
export default {
  name: 'page-change-password',
};
</script>
