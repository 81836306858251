<template>
  <div class="access-tab">
    <h5 class="card-title">Buildings</h5>
    <p class="text-muted mb-4">The buildings with the app shortcut.</p>
    <div v-if="appShortcut">
      <phillip-table
        table="building"
        hide-new-button
        hide-edit-button
        :hide-delete-button="appShortcut.isGlobal"
        delete-button-label="Remove from building"
        hide-filters
        empty-text="The app shortcut is not assigned to any building."
        :filters="filters"
        :on-delete="removeBuilding"
      />
      <div v-if="!appShortcut.isGlobal" class="mt-3 d-flex">
        <phillip-select
          style="min-width: 450px"
          select="building"
          placeholder="Add app shortcut to building"
          v-model="building"
        />
        <button @click="addBuilding" :disabled="building === null" class="btn btn-sm btn-primary text-white ml-3">
          Add to building
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import buildingFactory from '../../../model/building/building-factory';
import appShortcutFactory from '../../../model/app-shortcut/app-shortcut-factory';

export default {
  name: 'app-shortcut-detail-buildings-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    filters() {
      if (!this.appShortcut || this.appShortcut.isGlobal) {
        return {};
      }

      return {
        'appShortcut.eq': this.appShortcut.id,
      };
    },
  },
  data() {
    return {
      appShortcut: null,
      building: null,
    };
  },
  methods: {
    addBuilding() {
      if (this.building !== null) {
        appShortcutFactory
          .pkMock(this.pk)
          .addBuilding(this.building)
          .then(() => buildingFactory.signalChange())
          .catch((e) => this.$modal.alert(e));
      }
    },
    removeBuilding(buildingId) {
      this.$modal
        .confirm(`Remove app shortcut from building ${buildingId}?`)
        .then(() => {
          appShortcutFactory
            .pkMock(this.pk)
            .deleteBuilding(buildingId)
            .then(() => buildingFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
  created() {
    appShortcutFactory
      .pk(this.pk)
      .then((appShortcut) => (this.appShortcut = appShortcut))
      .catch((e) => this.$modal.alert(e));
  },
};
</script>
