<template>
  <div class="license-plates-tab">
    <h5 class="card-title">License Plates</h5>
    <p class="text-muted mb-4">
      The license plates of users who have access to the controlled entry are automatically uploaded to the LP
      recognition system.
    </p>
    <phillip-table
      table="license-plate"
      hide-filters
      empty-text="The user has no registered license plates."
      :filters="{ 'userControlledEntryAccess.eq': pk }"
      hide-delete-button
      hide-new-button
      hide-edit-button
      disable-detail
    />
  </div>
</template>

<script>
export default {
  name: 'user-detail-license-plates-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
