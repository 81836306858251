import ChangePassword from '../views/change-password/ChangePassword.vue';

import Step1 from '../views/change-password/steps/Step1.vue';
import Step2 from '../views/change-password/steps/Step2.vue';
import Step3 from '../views/change-password/steps/Step3.vue';

const changePassword = {
  path: '/change-password',
  name: 'Change password',
  component: ChangePassword,
  redirect: '/change-password/step/1',
  meta: { noLink: true },
};

changePassword.children = [
  {
    path: '/change-password/step/1',
    name: 'Step one',
    component: Step1,
    meta: { parent: changePassword },
  },
  {
    path: '/change-password/step/2',
    name: 'Step two',
    component: Step2,
    meta: { parent: changePassword },
  },
  {
    path: '/change-password/step/3',
    name: 'Step three',
    component: Step3,
    meta: { parent: changePassword },
  },
];

export default changePassword;
