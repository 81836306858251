<template>
  <card title="All buildings" description="The list of all buildings.">
    <new-building-button slot="title-button" class="ml-3" />
    <phillip-table hide-new-button table="building" />
  </card>
</template>

<script>
import NewBuildingButton from '../../components/buttons/NewBuildingButton';

export default {
  name: 'page-building',
  components: {
    NewBuildingButton,
  },
};
</script>
