<template>
  <div class="user-group-tab">
    <h5 class="card-title">User Group</h5>
    <p class="text-muted mb-4">The general info about the group.</p>
    <phillip-form form="user-group" />
  </div>
</template>

<script>
import userGroupFactory from '../../../model/user-group/user-group-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'user-group-detail-user-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setUserGroup: 'userGroup/form/setStorage',
    }),
    fetchData() {
      userGroupFactory
        .pk(this.pk)
        .then((user) => this.setUserGroup(user))
        .catch((err) => this.$modal.alert(err));
    },
  },
  watch: {
    pk: 'fetchData',
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    this.setUserGroup(null);
  },
};
</script>
