import DataStorageFactory from '../abstract/data-storage-factory.js';
import SipDoor from './sip-door-class';

/** @extends DataStorageFactory<SipDoor> */
class SipDoorFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'name';
  getModelName = () => 'sip-door';
  getPrettyName = () => 'SIP Door';
  getSingular = () => 'sip door';
  getPlural = () => 'sip doors';
  getIcon = () => 'feather-phone-forwarded';
}

const sipDoorFactory = new SipDoorFactory(SipDoor.prototype);

export default sipDoorFactory;
