<template>
  <div class="location-tab">
    <h5 class="card-title">Zone</h5>
    <p class="text-muted mb-4">The zone of the controlled entry.</p>
    <location-form v-if="controlledEntry" :storage="controlledEntry" />
  </div>
</template>

<script>
import LocationForm from '../../../components/forms/LocationForm';
import controlledEntryFactory from '../../../model/controlled-entry/controlled-entry-factory';

export default {
  name: 'controlled-entry-detail-location-tab',
  components: {
    LocationForm,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      controlledEntry: null,
    };
  },
  created() {
    this.controlledEntry = controlledEntryFactory.pkMock(this.pk);
  },
};
</script>
