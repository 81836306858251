<template>
  <article-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by language -->
      <div class="table-filter-row">
        <label for="article-table-filter-lang" class="table-filter-label"> Filter by language </label>
        <vue-select
          :options="[
            { value: 'sk', label: 'Slovak' },
            { value: 'en', label: 'English' },
            { value: 'cs', label: 'Czech' },
          ]"
          :reduce="({ value }) => value"
          id="article-table-filter-lang"
          v-model="lang"
          placeholder="All languages"
        />
      </div>
    </template>
  </article-table-base>
</template>

<script>
import ArticleTableBase from './ArticleTableBase';
import VueSelect from 'vue-select';

export default {
  name: 'article-table',
  components: {
    ArticleTableBase,
    VueSelect,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.lang) {
        filters['lang.eq'] = this.lang;
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      this.lang = null;
    },
  },
  data() {
    return {
      lang: null,
    };
  },
};
</script>
