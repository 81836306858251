<template>
  <div class="rfid-tab">
    <h5 class="card-title">RFID Card</h5>
    <p class="text-muted mb-4">The general info about the RFID card.</p>
    <phillip-form form="rfid" />
  </div>
</template>

<script>
import rfidFactory from '../../../model/rfid/rfid-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'rfid-detail-rfid-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setRfid: 'rfid/form/setStorage',
    }),
    fetchData() {
      rfidFactory
        .pk(this.pk)
        .then((rfid) => this.setRfid(rfid))
        .catch((err) => this.$modal.alert(err));
    },
  },
  watch: {
    pk: 'fetchData',
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    this.setRfid(null);
  },
};
</script>
