<template>
  <card
    class="short"
    title="New RFID"
    description="The general info about the RFID. More settings will be available after the RFID is created."
  >
    <phillip-form form="rfid" />
  </card>
</template>

<script>
import rfidFactory from '../../model/rfid/rfid-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-rfid',
  methods: {
    ...mapMutations({
      setRfid: 'rfid/form/setStorage',
    }),
  },
  created() {
    this.setRfid(rfidFactory.blank());
  },
  beforeDestroy() {
    this.setUser(null);
  },
};
</script>
