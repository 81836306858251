<template>
  <div>
    <p class="mb-3 mt--3 text-muted">The list of all Chastia aggregate functions known by Phillip backend.</p>
    <phillip-table hide-new-button hide-delete-button hide-edit-button disable-detail table="chastia-function" />
  </div>
</template>

<script>
export default {
  name: 'chastia-sensor-list-functions-tab',
};
</script>
