<template>
  <div class="areas-tab">
    <h5 class="card-title">Zones</h5>
    <p class="text-muted mb-4">The zones which the user can access.</p>
    <phillip-table
      table="area"
      hide-filters
      empty-text="The user can access no zones."
      :filters="{ 'userAccess.eq': this.pk }"
      hide-delete-button
      edit-button-label="Detail"
      hide-new-button
    />
  </div>
</template>

<script>
export default {
  name: 'user-detail-areas-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
