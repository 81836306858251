/** @typedef {import('./data-storage-factory').default} DataStorageFactory */

/**
 * Abstract class used as a base for every data storage
 *
 * DataStorage stores the actual and initial values of the object properties
 */
class DataStorage {
  /**
   * Returns the object sent to the POST and PUT endpoints.
   *
   * This method MUST be overridden.
   *
   * @returns {Object}
   */
  apiPayload() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the value saved under the primary key
   *
   * @returns {string|number}
   */
  pk() {
    return this[this._factory.getPrimaryKey()];
  }

  /**
   * Returns the value saved under the title key
   *
   * @return {string}
   */
  tk() {
    return this[this._factory.getTitleKey()];
  }

  /**
   * Converts the storage to string with a pretty formatting. Not all
   * properties must be used, but all searchable properties should be used
   *
   * This method SHOULD be overridden
   *
   * @returns {string}
   */
  pretty() {
    return this.tk();
  }

  /**
   * `true` if this is a newly created DataStorage by this app - not yet in db
   *
   * @type {boolean}
   */
  get isNew() {
    return this[this._factory.getPrimaryKey()] == undefined;
  }

  /**
   * Saves the DataStorage to database
   */
  async save() {
    await this._factory.save(this);

    return this;
  }

  /**
   * Deletes the DataStorage from database
   */
  async delete() {
    await this._factory.delete(this);

    return this;
  }

  /**
   * Creates a new instance of DataStorage
   *
   * @param {Object} props
   * @param {DataStorage} factory
   */
  constructor(props, factory) {
    Object.assign(this, props);

    Object.defineProperty(this, '_factory', {
      configurable: false,
      enumerable: false,
      value: factory,
      writable: false,
    });

    /**
     * @type {DataStorageFactory}
     */
    this._factory;
  }
}

export default DataStorage;
