<template>
  <div class="chastia-device-lazy-display">
    <span v-if="loading">Loading ...</span>
    <span v-else-if="!pk">-</span>
    <span v-else-if="storage">
      <span v-if="noLink">{{ storage.tk() }}</span>
      <router-link v-else :to="`/chastia-device/detail/${pk}`">
        {{ storage.tk() }}
      </router-link>
    </span>
    <span v-else>Loading ...</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'chastia-device-lazy-display',
  props: {
    pk: {},
    loading: {
      type: Boolean,
      default: false,
    },
    noLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      storage: null,
    };
  },
  computed: {
    ...mapGetters({
      storages: 'chastiaDevice/lazyDisplay/getStorages',
    }),
  },
  methods: {
    ...mapActions({
      addPk: 'chastiaDevice/lazyDisplay/addPk',
    }),
  },
  created() {
    if (this.pk) {
      this.addPk(this.pk);
    }
  },
  watch: {
    pk(pk) {
      if (pk) {
        this.addPk(pk);
      }
    },
    storages(storages) {
      if (!this.storage) {
        this.storage = storages[this.pk];
      }
    },
  },
};
</script>
