<template>
  <component :is="`${select}-select`" v-bind="$attrs" v-model="selected" />
</template>

<script>
import AccessTokenSelect from './AccessTokenSelect';
import AppShortcutSelect from './AppShortcutSelect';
import AreaSelect from './AreaSelect';
import ArticleSelect from './ArticleSelect';
import BillingAddressSelect from './BillingAddressSelect';
import BuildingSelect from './BuildingSelect';
import CameraSelect from './CameraSelect';
import ChastiaDeviceSelect from './ChastiaDeviceSelect';
import ChastiaDeviceMeterTypeSelect from './ChastiaDeviceMeterTypeSelect';
import ChastiaFunctionSelect from './ChastiaFunctionSelect';
import ChastiaSensorSelect from './ChastiaSensorSelect';
import ChastiaSensorMeasurementSelect from './ChastiaSensorMeasurementSelect';
import ChastiaSensorMeasuringTypeSelect from './ChastiaSensorMeasuringTypeSelect';
import ControlledEntrySelect from './ControlledEntrySelect';
import DoorBellSelect from './DoorBellSelect';
import EmailMessageSelect from './EmailMessageSelect';
import FaqSelect from './FaqSelect';
import FirebaseMessageSelect from './FirebaseMessageSelect';
import IosVoipMessageSelect from './IosVoipMessageSelect';
import LicensePlateSelect from './LicensePlateSelect';
import RfidSelect from './RfidSelect';
import RfidOwnerChangelogSelect from './RfidOwnerChangelogSelect';
import SessionSelect from './SessionSelect';
import SipDoorSelect from './SipDoorSelect';
import SipDoorActionSelect from './SipDoorActionSelect';
import UserSelect from './UserSelect';
import UserGroupSelect from './UserGroupSelect';
import UserSipAccountSelect from './UserSipAccountSelect';

export default {
  name: 'phillip-select',
  components: {
    AccessTokenSelect,
    AppShortcutSelect,
    AreaSelect,
    ArticleSelect,
    BillingAddressSelect,
    BuildingSelect,
    CameraSelect,
    ChastiaDeviceSelect,
    ChastiaDeviceMeterTypeSelect,
    ChastiaFunctionSelect,
    ChastiaSensorSelect,
    ChastiaSensorMeasurementSelect,
    ChastiaSensorMeasuringTypeSelect,
    ControlledEntrySelect,
    DoorBellSelect,
    EmailMessageSelect,
    FaqSelect,
    FirebaseMessageSelect,
    IosVoipMessageSelect,
    LicensePlateSelect,
    RfidSelect,
    RfidOwnerChangelogSelect,
    SessionSelect,
    SipDoorSelect,
    SipDoorActionSelect,
    UserSelect,
    UserGroupSelect,
    UserSipAccountSelect,
  },
  props: {
    select: {
      type: String,
      required: true,
    },
    value: {},
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.selected = newValue;
      }
    },
    selected() {
      this.$emit('input', this.selected);
    },
  },
};
</script>
