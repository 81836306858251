<template>
  <modal v-if="form" class="form-modal" :heading="title" confirm-button-text="Save" @close="close">
    <template slot="body">
      <phillip-form
        ref="form"
        form-id="phillip-form-modal"
        :form="form"
        v-bind="$attrs"
        hide-submit-button
        no-redirect
        @success="close"
      />
    </template>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from './Modal.vue';

export default {
  name: 'form-modal',
  components: {
    Modal,
  },
  computed: {
    ...mapGetters({
      form: 'modal/getForm',
    }),
    storage() {
      return this.$store.getters[`${this.form}/form/getStorage`];
    },
    model() {
      if (this.storage === null) {
        return '';
      }

      return this.storage._factory.getPrettyName();
    },
    title() {
      const action = this.storage.isNew ? 'New' : 'Editing';

      return `${action} ${this.model}`;
    },
  },
  methods: {
    ...mapActions({
      close: 'modal/close',
    }),
  },
};
</script>

<style lang="scss">
.form-modal .modal-title {
  text-transform: capitalize;
}
</style>
