<template>
  <form class="forms-sample" @submit.prevent="nextStep">
    <p class="text-muted mb-4">Please, enter your email address.</p>
    <div class="form-group mb-3">
      <label for="email">
        Email address
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <input v-model="email" required type="email" class="form-control" id="email" placeholder="Email" />
    </div>
    <div class="mt-4 d-flex align-items-center">
      <button type="submit" class="btn btn-primary btn-sm text-white">Submit</button>
      <router-link to="/login" class="text-muted ml-4">Cancel</router-link>
    </div>
  </form>
</template>

<script>
import connector from '../../../api/connector';
import { mapMutations } from 'vuex';

export default {
  name: 'reset-password-step-1',
  data() {
    return {
      email: '',
    };
  },
  methods: {
    ...mapMutations({
      setToken: 'resetPassword/setToken',
    }),
    nextStep() {
      connector
        .post('/reset-password/request', { email: this.email })
        .then(({ data }) => {
          this.setToken(data.token);
          this.$router.push('/reset-password/step/2');
        })
        .catch((e) => this.$modal.alert(e));
    },
  },
};
</script>
