<template>
  <div class="groups-tab">
    <h5 class="card-title">Groups</h5>
    <p class="text-muted mb-4">The groups with access to the building.</p>
    <phillip-table
      table="user-group"
      hide-filters
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      empty-text="No group has access to the building."
      :filters="{ 'buildingAccess.eq': this.pk, 'owner.null': true }"
    />
  </div>
</template>

<script>
export default {
  name: 'building-detail-groups-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
