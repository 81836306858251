import DataStorageFactory from '../abstract/data-storage-factory.js';
import User from './user-class';

/** @extends DataStorageFactory<User> */
class UserFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'fullName';
  getModelName = () => 'user';
  getPrettyName = () => 'User';
  getSingular = () => 'user';
  getPlural = () => 'users';
  getIcon = () => 'feather-user';
}

const userFactory = new UserFactory(User.prototype);

export default userFactory;
