<template>
  <div class="controlled-entries-tab">
    <h5 class="card-title">Controlled Entries</h5>
    <p class="text-muted mb-1">The controlled entries which the user can access.</p>
    <ul class="text-muted mb-4">
      <li>
        All controlled entries accessible by at least one group the user is a member of will be also accessible by the
        user.
      </li>
    </ul>
    <phillip-table
      table="controlled-entry"
      hide-new-button
      hide-filters
      hide-delete-button
      edit-button-label="Detail"
      empty-text="The user can access no controlled entries."
      :filters="{ 'userAccess.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'user-detail-controlled-entries-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
