<template>
  <div class="access-tab">
    <h5 class="card-title">Articles</h5>
    <p class="text-muted mb-4">The articles visible to the users in the building.</p>
    <phillip-table
      table="article"
      hide-new-button
      hide-edit-button
      delete-button-label="Remove article"
      hide-filters
      empty-text="The building has no articles."
      :filters="{ 'building.eq': pk }"
      :on-delete="removeArticle"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="article"
        placeholder="Add article to building"
        v-model="article"
      />
      <button @click="addArticle" :disabled="article === null" class="btn btn-sm btn-primary text-white ml-3">
        Add article
      </button>
    </div>
  </div>
</template>

<script>
import buildingFactory from '../../../model/building/building-factory';
import articleFactory from '../../../model/article/article-factory';

export default {
  name: 'building-detail-articles-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      article: null,
    };
  },
  methods: {
    addArticle() {
      if (this.article !== null) {
        buildingFactory
          .pkMock(this.pk)
          .addArticle(this.article)
          .then(() => articleFactory.signalChange())
          .catch((e) => this.$modal.alert(e));
      }
    },
    removeArticle(articleId) {
      this.$modal
        .confirm(`Remove article #${articleId} from building?`)
        .then(() => {
          buildingFactory
            .pkMock(this.pk)
            .deleteArticle(articleId)
            .then(() => articleFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
