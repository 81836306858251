<template>
  <card title="All zones" description="The list of all zones.">
    <new-area-button slot="title-button" class="ml-3" />
    <phillip-table table="area" hide-new-button />
  </card>
</template>

<script>
import NewAreaButton from '../../components/buttons/NewAreaButton';

export default {
  name: 'page-area',
  components: {
    NewAreaButton,
  },
};
</script>
