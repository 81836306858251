<template>
  <card class="short page-user-group-detail">
    <!-- Problems -->
    <div v-if="hasProblems" class="alert alert-warning d-flex align-items-center">
      <icon icon="feather-alert-circle" />
      <div class="ml-3">
        <p v-if="problems.member">The group is empty.</p>
        <p v-if="problems.access">The group can access no zones.</p>
      </div>
    </div>

    <!-- Navigation -->
    <nav class="card-nav mb-4" role="group">
      <router-link :to="t('user-group')">Group</router-link>
      <router-link :to="t('members')">Members</router-link>
      <router-link :to="t('zones')">Zones</router-link>
      <div class="spacer"></div>
      <router-link :to="t('sip-doors')">SIP Doors</router-link>
      <router-link :to="t('controlled-entries')">Controlled Entries</router-link>
      <router-link :to="t('cameras')">Cameras</router-link>
    </nav>

    <!-- Tab content -->
    <main class="pt-2">
      <router-view />
    </main>
  </card>
</template>

<script>
import userGroupFactory from '../../model/user-group/user-group-factory';

export default {
  name: 'page-user-group-detail',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    hasProblems() {
      return Object.values(this.problems).some(Boolean);
    },
  },
  data() {
    return {
      problems: {},
      submitHandler: null,
    };
  },
  methods: {
    t(path) {
      return `/user-group/detail/${this.pk}/${path}`;
    },
    async fetchProblems() {
      this.problems = await userGroupFactory.pkMock(this.pk).computeProblems();
    },
  },
  created() {
    // todo: do this better
    this.submitHandler = () => this.fetchProblems();
    window.addEventListener('dataStorageFactory.signalChange', this.submitHandler);
    this.fetchProblems();
  },
  beforeDestroy() {
    window.removeEventListener('dataStorageFactory.signalChange', this.submitHandler);
  },
};
</script>
