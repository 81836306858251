<template>
  <div class="cameras-tab">
    <h5 class="card-title">Changelog</h5>
    <p class="text-muted mb-4">The history of ownership changes for this RFID.</p>
    <phillip-table
      table="rfid-owner-changelog"
      hide-new-button
      hide-filters
      hide-delete-button
      hide-edit-button
      :filters="{ 'rfidId.eq': pk }"
      :excludeColumns="['rfidId']"
    />
  </div>
</template>

<script>
export default {
  name: 'area-detail-cameras-tab',
  computed: {
    pk() {
      return Number(this.$route.params.pk);
    },
  },
};
</script>
