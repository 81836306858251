<template>
  <component :is="`${table}-table`" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import AccessTokenTable from './access-token/AccessTokenTable';
import AppShortcutTable from './app-shortcut/AppShortcutTable';
import AreaTable from './area/AreaTable';
import ArticleTable from './article/ArticleTable';
import BuildingTable from './building/BuildingTable';
import CameraTable from './camera/CameraTable';
import ChastiaDeviceTable from './chastia-device/ChastiaDeviceTable';
import ChastiaDeviceMeterTypeTable from './chastia-device-meter-type/ChastiaDeviceMeterTypeTable';
import ChastiaFunctionTable from './chastia-function/ChastiaFunctionTable';
import ChastiaSensorTable from './chastia-sensor/ChastiaSensorTable';
import ChastiaSensorMeasurementTable from './chastia-sensor-measurement/ChastiaSensorMeasurementTable';
import ChastiaSensorMeasuringTypeTable from './chastia-sensor-measuring-type/ChastiaSensorMeasuringTypeTable';
import ControlledEntryTable from './controlled-entry/ControlledEntryTable';
import DoorBellTable from './door-bell/DoorBellTable';
import EmailMessageTable from './email-message/EmailMessageTable';
import FaqTable from './faq/FaqTable';
import FirebaseMessageTable from './firebase-message/FirebaseMessageTable';
import IosVoipMessageTable from './ios-voip-message/IosVoipMessageTable';
import LicensePlateTable from './license-plate/LicensePlateTable';
import RfidTable from './rfid/RfidTable';
import RfidOwnerChangelogTable from './rfid-owner-changelog/RfidOwnerChangelogTable';
import SessionTable from './session/SessionTable';
import SipDoorTable from './sip-door/SipDoorTable';
import SipDoorActionTable from './sip-door-action/SipDoorActionTable';
import UserTable from './user/UserTable';
import UserGroupTable from './user-group/UserGroupTable';
import UserSipAccountTable from './user-sip-account/UserSipAccountTable';

export default {
  name: 'phillip-table',
  props: {
    table: {
      type: String,
      required: true,
    },
  },
  components: {
    AccessTokenTable,
    AppShortcutTable,
    AreaTable,
    ArticleTable,
    BuildingTable,
    CameraTable,
    ChastiaDeviceTable,
    ChastiaDeviceMeterTypeTable,
    ChastiaFunctionTable,
    ChastiaSensorTable,
    ChastiaSensorMeasurementTable,
    ChastiaSensorMeasuringTypeTable,
    ControlledEntryTable,
    DoorBellTable,
    EmailMessageTable,
    FaqTable,
    FirebaseMessageTable,
    IosVoipMessageTable,
    LicensePlateTable,
    RfidTable,
    RfidOwnerChangelogTable,
    SessionTable,
    SipDoorTable,
    SipDoorActionTable,
    UserTable,
    UserGroupTable,
    UserSipAccountTable,
  },
};
</script>
