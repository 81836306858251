<template>
  <div class="app-shortcut-tab">
    <h5 class="card-title">App Shortcut</h5>
    <p class="text-muted mb-4">The general info about the app shortcut.</p>
    <phillip-form form="app-shortcut" />
  </div>
</template>

<script>
import appShortcutFactory from '../../../model/app-shortcut/app-shortcut-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-app-shortcut',
  methods: {
    ...mapMutations({
      setAppShortcut: 'appShortcut/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    appShortcutFactory
      .pk(this.pk)
      .then((appShortcut) => this.setAppShortcut(appShortcut))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setAppShortcut(null);
  },
};
</script>
