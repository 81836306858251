<template>
  <card class="api-playground-page" title="API Playground" description="Send GET requests to the REST API.">
    <form @submit.prevent="send">
      <!-- Method & endpoint -->
      <section class="d-flex mb-3">
        <vue-select
          :clearable="false"
          style="width: 100px; background-color: rgb(245, 245, 245)"
          :options="['GET', 'POST', 'PUT']"
          v-model="method"
        />
        <text-input class="ml-1" style="max-width: 500px" placeholder="/example" v-model="endpoint" />
        <button type="submit" style="display: none"></button>
      </section>

      <!-- Body -->
      <section v-show="method !== 'GET'">
        <h5 class="text-muted mt-3 mb-2">Body</h5>
        <textarea-input />
      </section>

      <!-- Response -->
      <section>
        <h5 class="text-muted mt-3 mb-2">Response</h5>
        <pre style="border: 1px solid #eee" v-html="codeContents"></pre>
      </section>
    </form>
  </card>
</template>

<script>
import connector from '../../api/connector';
import VueSelect from 'vue-select';
import TextInput from '../../components/inputs/TextInput.vue';
import TextareaInput from '../../components/inputs/TextareaInput.vue';

export default {
  name: 'api-playground-page',
  components: {
    TextInput,
    VueSelect,
    TextareaInput,
  },
  data() {
    return {
      loading: false,
      error: null,
      response: null,
      method: 'GET',
      endpoint: '',
    };
  },
  computed: {
    codeContents() {
      if (this.error) {
        return this.error + (this.error.response ? `\n\n${JSON.stringify(this.error.response.data, null, 2)}` : '');
      }

      if (this.loading) {
        return 'Loading ...';
      }

      return this.response;
    },
  },
  methods: {
    async send() {
      try {
        this.loading = true;
        this.error = null;

        const { data } = await connector[this.method.toLowerCase()](this.endpoint);
        this.response = data;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
