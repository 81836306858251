import DataStorageFactory from '../abstract/data-storage-factory.js';
import Area from './area-class';

/** @extends DataStorageFactory<Area> */
class AreaFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'title';
  getModelName = () => 'area';
  getPrettyName = () => 'Zone';
  getPlural = () => 'zones';
  getSingular = () => 'zone';
  getIcon = () => 'feather-map-pin';
}

const areaFactory = new AreaFactory(Area.prototype);

export default areaFactory;
