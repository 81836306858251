<template>
  <div class="user-access-tab">
    <h4 class="card-title">Functions</h4>
    <p class="text-muted mb-4">The functions supported by the Chastia sensor.</p>
    <phillip-table
      table="chastia-function"
      hide-filters
      hide-new-button
      hide-edit-button
      hide-delete-button
      disable-detail
      empty-text="The sensor supports no functions."
      :filters="{ 'chastiaSensorId.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'chastia-sensor-detail-user-access-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
