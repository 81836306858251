<template>
  <input class="form-control" placeholder="https://example.com" v-model="valueStorage" v-bind="properties" type="url" />
</template>

<script>
export default {
  name: 'url-input',
  props: ['value', 'properties', 'type'],
  data() {
    return {
      valueStorage: this.value,
    };
  },
  watch: {
    valueStorage() {
      this.$emit('input', this.valueStorage);
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = newValue;
      }
    },
  },
};
</script>
