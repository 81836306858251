<template>
  <card title="All cameras" description="The list of all cameras.">
    <new-camera-button slot="title-button" class="ml-3" />
    <phillip-table hide-new-button table="camera" />
  </card>
</template>

<script>
import NewCameraButton from '../../components/buttons/NewCameraButton';

export default {
  name: 'page-camera',
  components: {
    NewCameraButton,
  },
};
</script>
