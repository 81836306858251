<template>
  <div class="whitelist-tab">
    <h5 class="card-title">Whitelist</h5>
    <p class="text-muted mb-4">The list of groups which are granted access to the controlled entry.</p>
    <whitelist-form :storage="controlledEntryFactory.pkMock(pk)" table-filter-name="controlledEntryWhitelist.eq" />
  </div>
</template>

<script>
import controlledEntryFactory from '../../../model/controlled-entry/controlled-entry-factory';
import WhitelistForm from '../../../components/forms/WhitelistForm.vue';

export default {
  name: 'controlled-entry-detail-whitelist-tab',
  components: {
    WhitelistForm,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      controlledEntryFactory,
    };
  },
};
</script>
