import DataStorageFactory from '../abstract/data-storage-factory.js';
import ChastiaFunction from './chastia-function-class';

/** @extends DataStorageFactory<ChastiaFunction> */
class ChastiaFunctionFactory extends DataStorageFactory {
  getPrimaryKey = () => 'chastiaFunctionId';
  getTitleKey = () => 'chastiaName';
  getModelName = () => 'chastia-function';
  getPrettyName = () => 'Chastia Function';
}

const chastiaFunctionFactory = new ChastiaFunctionFactory(ChastiaFunction.prototype);

export default chastiaFunctionFactory;
