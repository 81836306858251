import DataStorageFactory from '../abstract/data-storage-factory.js';
import Building from './building-class';

/** @extends DataStorageFactory<Building> */
class BuildingFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'title';
  getModelName = () => 'building';
  getPrettyName = () => 'Building';
  getSingular = () => 'building';
  getPlural = () => 'buildings';
  getIcon = () => 'feather-home';
}

const buildingFactory = new BuildingFactory(Building.prototype);

export default buildingFactory;
