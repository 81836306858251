<template>
  <card title="All Users" description="The list of all users.">
    <new-user-button slot="title-button" class="ml-3" />
    <phillip-table hide-new-button table="user" />
  </card>
</template>

<script>
import NewUserButton from '../../components/buttons/NewUserButton';

export default {
  name: 'page-user',
  components: {
    NewUserButton,
  },
};
</script>
