<template>
  <div class="sip-door-tab">
    <h5 class="card-title">SIP Door</h5>
    <p class="text-muted mb-4">The general info about the SIP door.</p>
    <phillip-form form="sip-door" />
  </div>
</template>

<script>
import sipDoorFactory from '../../../model/sip-door/sip-door-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-sip-door',
  methods: {
    ...mapMutations({
      setSipDoor: 'sipDoor/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    sipDoorFactory
      .pk(this.pk)
      .then((sipDoor) => this.setSipDoor(sipDoor))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setSipDoor(null);
  },
};
</script>
