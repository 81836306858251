<template>
  <door-bell-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters"></template>
  </door-bell-table-base>
</template>

<script>
import DoorBellTableBase from './DoorBellTableBase';

export default {
  name: 'door-bell-table',
  components: {
    DoorBellTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      // Clear the filters
    },
  },
  data() {
    return {
      // Filters
    };
  },
};
</script>
