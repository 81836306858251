var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"model-form",attrs:{"id":_vm.formId},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._l((_vm.groups),function([groupName, inputs]){return _c('div',{key:groupName},[(groupName)?_c('h5',[_vm._v(_vm._s(groupName))]):_vm._e(),_vm._l((inputs),function(cfg,key){return [(_vm.shouldDisplayInput(cfg))?[_c('div',{key:key,staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" "+_vm._s(cfg.label)+" "),(cfg.required)?_c('abbr',{staticClass:"text-danger",attrs:{"title":"This field is required"}},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"col-sm-10",style:({ maxWidth: cfg.type === 'tiny-mce' ? '' : '600px' })},[(cfg.type === 'tiny-mce')?[_c('tiny-mce',{attrs:{"api-key":_vm.TINY_MCE_KEY,"init":{
                  plugins:
                    'autolink autoresize autosave charmap emoticons help image insertdatetime link lists media preview quickbars searchreplace table visualblocks visualchars wordcount',
                  toolbar:
                    'formatselect | bold italic underline strikethrough link | alignleft aligncenter alignright | bullist numlist | quickimage media table | emoticons',
                  quickbars_insert_toolbar: false,
                  toolbar_sticky: true,
                  toolbar_sticky_offset: 60,
                }},model:{value:(_vm.storage[key]),callback:function ($$v) {_vm.$set(_vm.storage, key, $$v)},expression:"storage[key]"}})]:[_c(cfg.type,_vm._b({tag:"component",model:{value:(_vm.storage[key]),callback:function ($$v) {_vm.$set(_vm.storage, key, $$v)},expression:"storage[key]"}},'component',{ ...cfg, type: undefined, readonly: _vm.isReadonly(cfg) },false))]],2)])]:_vm._e()]})],2)}),(_vm.session.debug)?_c('code',[_c('pre',[_vm._v(_vm._s(_vm.storage))])]):_vm._e(),(!_vm.hideSubmitButton)?_c('button',{class:['btn', 'btn-sm', 'submit', 'text-white', _vm.success ? 'btn-success' : 'btn-primary'],attrs:{"disabled":_vm.loading,"type":"submit"}},[(_vm.loading)?[_vm._v(" Saving... "),_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}})]:(_vm.success)?[_vm._v("Saved successfully!")]:[_vm._v("Save")]],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }