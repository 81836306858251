/**
 * Returns `true` if the parameter is an object. A variable number of
 * parameters can be supplied. Every parameter must be an object in order for
 * `true` to be returned
 *
 * @returns {boolean}
 *
 */
const isObject = (...obj) => obj.every((o) => o !== null && typeof o === 'object');

export { isObject };
