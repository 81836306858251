<template>
  <div class="location-tab">
    <h5 class="card-title">Zone</h5>
    <p class="text-muted mb-4">The zone of the SIP door.</p>
    <location-form v-if="sipDoor" :storage="sipDoor" />
  </div>
</template>

<script>
import LocationForm from '../../../components/forms/LocationForm';
import sipDoorFactory from '../../../model/sip-door/sip-door-factory';

export default {
  name: 'sip-door-detail-location-tab',
  components: {
    LocationForm,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      sipDoor: null,
    };
  },
  created() {
    this.sipDoor = sipDoorFactory.pkMock(this.pk);
  },
};
</script>
