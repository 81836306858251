import DataStorageFactory from '../abstract/data-storage-factory.js';
import ControlledEntry from './controlled-entry-class';

/** @extends DataStorageFactory<ControlledEntry> */
class ControlledEntryFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'name';
  getModelName = () => 'controlled-entry';
  getPrettyName = () => 'Controlled Entry';
  getSingular = () => 'controlled entry';
  getPlural = () => 'controlled entries';
  getIcon = () => 'feather-compass';
}

const controlledEntryFactory = new ControlledEntryFactory(ControlledEntry.prototype);

export default controlledEntryFactory;
