<template>
  <user-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by role -->
      <div class="table-filter-row">
        <label for="user-table-filter-role" class="table-filter-label">Filter by role</label>
        <vue-select
          id="user-table-filter-role"
          placeholder="All roles"
          :options="['Customer', 'Admin']"
          v-model="role"
        />
      </div>

      <div class="spacer"></div>

      <!-- Filter by group -->
      <div class="table-filter-row">
        <label for="user-table-filter-user-group" class="table-filter-label"> Filter by group </label>
        <phillip-select
          select="user-group"
          id="user-table-filter-user-group"
          placeholder="All groups"
          v-model="userGroup"
          :filters="{ 'owner.null': true }"
        />
      </div>

      <div class="spacer"></div>

      <!-- Filter by area -->
      <div class="table-filter-row">
        <label for="user-table-filter-area" class="table-filter-label">Filter by zone access</label>
        <phillip-select select="area" id="user-table-filter-area" placeholder="All zones" v-model="area" />
      </div>
    </template>
  </user-table-base>
</template>

<script>
import UserTableBase from './UserTableBase';
import VueSelect from 'vue-select';

export default {
  name: 'user-table',
  components: {
    UserTableBase,
    VueSelect,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.role) {
        filters['role.eq'] = this.role.toLowerCase();
      }

      if (this.userGroup) {
        filters['groupId.eq'] = this.userGroup;
      }

      if (this.area) {
        filters['areaAccess.eq'] = this.area;
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  data() {
    return {
      role: null,
      userGroup: null,
      area: null,
    };
  },
  methods: {
    clearFilters() {
      this.role = null;
      this.userGroup = null;
      this.area = null;
    },
  },
};
</script>
