<template>
  <chastia-device-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by building -->
      <div class="table-filter-row">
        <label class="table-filter-label">Filter by building</label>
        <phillip-select select="building" placeholder="All buildings" v-model="building" />
      </div>

      <!-- Filter by area -->
      <div class="table-filter-row">
        <label class="table-filter-label">Filter by zone</label>
        <phillip-select select="area" placeholder="All zones" v-model="area" :filters="{ 'buildingId.eq': building }" />
      </div>
    </template>
  </chastia-device-table-base>
</template>

<script>
import ChastiaDeviceTableBase from './ChastiaDeviceTableBase';

export default {
  name: 'chastia-device-table',
  components: {
    ChastiaDeviceTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.area) {
        filters['areaId.eq'] = this.area;
      }

      if (this.building) {
        filters['buildingId.eq'] = this.building;
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      this.building = null;
      this.area = null;
    },
  },
  data() {
    return {
      building: null,
      area: null,
    };
  },
};
</script>
