<template>
  <card
    class="short"
    title="New User"
    description="The general info about the user. More settings will be available after the user is created."
  >
    <phillip-form form="user" />
  </card>
</template>

<script>
import userFactory from '../../model/user/user-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-user',
  methods: {
    ...mapMutations({
      setUser: 'user/form/setStorage',
    }),
  },
  created() {
    this.setUser(userFactory.blank());
  },
  beforeDestroy() {
    this.setUser(null);
  },
};
</script>
