import home from './home';
import ChastiaDevice from '../views/chastia-device/ChastiaDevice.vue';
import ChastiaDeviceDetail from '../views/chastia-device/ChastiaDeviceDetail.vue';

const chastiaDevice = {
  path: '/chastia-device',
  name: 'Chastia Devices',
  component: ChastiaDevice,
  meta: { parent: home },
};

const chastiaDeviceDetail = {
  path: '/chastia-device/detail/:pk',
  name: 'Chastia Device Detail',
  redirect: '/chastia-device/detail/:pk/chastia-device',
  component: ChastiaDeviceDetail,
  meta: { parent: chastiaDevice, noLink: true },
};

// Tabs
import ChastiaDeviceTab from '../views/chastia-device/tabs/ChastiaDeviceTab.vue';
import SensorsTab from '../views/chastia-device/tabs/SensorsTab.vue';
import LocationTab from '../views/chastia-device/tabs/LocationTab.vue';
import BlacklistTab from '../views/chastia-device/tabs/BlacklistTab.vue';
import WhitelistTab from '../views/chastia-device/tabs/WhitelistTab.vue';
import UserGroupAccessTab from '../views/chastia-device/tabs/UserGroupAccessTab.vue';
import UserAccessTab from '../views/chastia-device/tabs/UserAccessTab.vue';
// import RawTab from '../views/chastia-device/tabs/RawTab.vue';
import ChastiaApiTab from '../views/chastia-device/tabs/ChastiaApiTab.vue';

chastiaDeviceDetail.children = [
  {
    path: '/chastia-device/detail/:pk/chastia-device',
    name: 'Chastia Device',
    component: ChastiaDeviceTab,
    meta: { parent: chastiaDeviceDetail },
  },
  {
    path: '/chastia-device/detail/:pk/sensors',
    name: 'Sensors',
    component: SensorsTab,
    meta: { parent: chastiaDeviceDetail },
  },
  {
    path: '/chastia-device/detail/:pk/location',
    name: 'Zone',
    component: LocationTab,
    meta: { parent: chastiaDeviceDetail },
  },
  {
    path: '/chastia-device/detail/:pk/blacklist',
    name: 'Blacklist',
    component: BlacklistTab,
    meta: { parent: chastiaDeviceDetail },
  },
  {
    path: '/chastia-device/detail/:pk/whitelist',
    name: 'Whitelist',
    component: WhitelistTab,
    meta: { parent: chastiaDeviceDetail },
  },
  {
    path: '/chastia-device/detail/:pk/user-group-access',
    name: 'Groups',
    component: UserGroupAccessTab,
    meta: { parent: chastiaDeviceDetail },
  },
  {
    path: '/chastia-device/detail/:pk/user-access',
    name: 'Users',
    component: UserAccessTab,
    meta: { parent: chastiaDeviceDetail },
  },
  // {
  //   path: '/chastia-device/detail/:pk/raw',
  //   name: 'Raw',
  //   component: RawTab,
  //   meta: { parent: chastiaDeviceDetail },
  // },
  {
    path: '/chastia-device/detail/:pk/chastia-api',
    name: 'Chastia API',
    component: ChastiaApiTab,
    meta: { parent: chastiaDeviceDetail },
  },
];

const chastiaDeviceRoutes = [chastiaDevice, chastiaDeviceDetail];

export default chastiaDeviceRoutes;
