<template>
  <card class="short page-camera-detail">
    <!-- Problems -->
    <div v-if="hasProblems" class="alert alert-warning d-flex align-items-center">
      <icon icon="feather-alert-circle" />
      <div class="ml-3">
        <p v-if="problems.location">The zone of the camera is not set.</p>
        <p v-if="problems.access">No user has access to the camera.</p>
      </div>
    </div>

    <!-- Navigation -->
    <nav class="card-nav mb-4" role="group">
      <router-link :to="t('camera')">Camera</router-link>
      <router-link :to="t('location')">Zone</router-link>
      <div class="spacer"></div>
      <router-link :to="t('blacklist')">Blacklist</router-link>
      <router-link :to="t('whitelist')">Whitelist</router-link>
      <div class="spacer"></div>
      <router-link :to="t('user-group-access')">Groups</router-link>
      <router-link :to="t('user-access')">Users</router-link>
      <!-- <div class="spacer"></div>
      <router-link :to="t('snapshot')">Snapshot</router-link>
      <router-link :to="t('livestream')">Livestream</router-link> -->
    </nav>

    <!-- Tab content -->
    <main class="pt-2">
      <router-view />
    </main>
  </card>
</template>

<script>
import cameraFactory from '../../model/camera/camera-factory';

export default {
  name: 'page-camera-detail',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    hasProblems() {
      return Object.values(this.problems).some(Boolean);
    },
  },
  data() {
    return {
      problems: {},
      submitHandler: null,
    };
  },
  methods: {
    t(path) {
      return `/camera/detail/${this.pk}/${path}`;
    },
    async fetchProblems() {
      this.problems = await cameraFactory.pkMock(this.pk).computeProblems();
    },
  },
  created() {
    // todo: do this better
    this.submitHandler = () => this.fetchProblems();
    window.addEventListener('dataStorageFactory.signalChange', this.submitHandler);

    this.fetchProblems();
  },
  beforeDestroy() {
    window.removeEventListener('dataStorageFactory.signalChange', this.submitHandler);
  },
};
</script>
