<template>
  <div class="dashboard-layout main-wrapper">
    <sidebar />
    <div class="page-wrapper">
      <navbar />
      <div class="page-content">
        <breadcrumbs class="page-breadcrumb"></breadcrumbs>
        <router-view />
      </div>
      <p id="creanet-text">
        Created with <span id="creanet-heart">❤</span> by
        <a href="https://creanet.sk" rel="external" target="_blank">CREANET</a>.
      </p>
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';

export default {
  name: 'dashboard-layout',
  components: {
    Sidebar,
    Navbar,
    Breadcrumbs,
  },
};
</script>
