<template>
  <div class="email-message-tab">
    <h5 class="card-title">Email Message</h5>
    <p class="text-muted mb-4">The general info about the email message.</p>
    <phillip-form form="email-message" />
  </div>
</template>

<script>
import emailMessageFactory from '../../../model/email-message/email-message-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-email-message',
  methods: {
    ...mapMutations({
      setEmailMessage: 'emailMessage/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    emailMessageFactory
      .pk(this.pk)
      .then((emailMessage) => this.setEmailMessage(emailMessage))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setEmailMessage(null);
  },
};
</script>
