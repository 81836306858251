<template>
  <div class="chastia-devices-tab">
    <h5 class="card-title">Chastia Devices</h5>
    <p class="text-muted mb-4">The Chastia devices in the zone.</p>
    <area-devices-form :factory="factory" :area-id="pk" />
  </div>
</template>

<script>
import chastiaDeviceFactory from '../../../model/chastia-device/chastia-device-factory';
import AreaDevicesForm from '../../../components/forms/AreaDevicesForm';

export default {
  name: 'area-detail-chastia-devices-tab',
  components: {
    AreaDevicesForm,
  },
  computed: {
    pk() {
      return Number(this.$route.params.pk);
    },
  },
  data() {
    return {
      factory: chastiaDeviceFactory,
    };
  },
};
</script>
