<template>
  <div class="whitelist-tab">
    <h5 class="card-title">Whitelist</h5>
    <p class="text-muted mb-4">The list of groups which are granted access to the camera.</p>
    <whitelist-form :storage="cameraFactory.pkMock(pk)" table-filter-name="cameraWhitelist.eq" />
  </div>
</template>

<script>
import cameraFactory from '../../../model/camera/camera-factory';
import WhitelistForm from '../../../components/forms/WhitelistForm.vue';

export default {
  name: 'camera-detail-whitelist-tab',
  components: {
    WhitelistForm,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      cameraFactory,
    };
  },
};
</script>
