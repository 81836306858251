import DataStorageFactory from '../abstract/data-storage-factory.js';
import LicensePlate from './license-plate-class';

/** @extends DataStorageFactory<LicensePlate> */
class LicensePlateFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'code';
  getModelName = () => 'license-plate';
  getPrettyName = () => 'License Plate';
  getSingular = () => 'license plate';
  getPlural = () => 'license plates';
  getIcon = () => 'feather-truck';

  /**
   * Returns the license plates registered by the specified user
   *
   * @param {number} userId
   */
  ofUser(userId) {
    return this.fetch({ 'userId.eq': userId });
  }
}

const licensePlateFactory = new LicensePlateFactory(LicensePlate.prototype);

export default licensePlateFactory;
