<template>
  <div class="sip-account-tab">
    <h5 class="card-title">SIP Account</h5>
    <p class="text-muted mb-4">The user's SIP account.</p>
    <phillip-form no-redirect form="user-sip-account" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import userSipAccountFactory from '../../../model/user-sip-account/user-sip-account-factory';

export default {
  name: 'user-detail-sip-account-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setUserSipAccount: 'userSipAccount/form/setStorage',
    }),
  },
  created() {
    userSipAccountFactory
      .forUser(this.pk)
      .then((userSipAccount) => this.setUserSipAccount(userSipAccount))
      .catch((err) => this.$modal.alert(err));
  },
  beforeDestroy() {
    this.setUserSipAccount(null);
  },
};
</script>
