<template>
  <div class="actions-tab">
    <h5 class="card-title">Actions</h5>
    <p class="text-muted mb-4">Actions that can be performed on the user. Click on an action to perform it.</p>

    <div v-if="welcomeEmailSent" class="alert alert-success">
      <icon class="mr-3" icon="feather-check-circle" />
      <span>Welcome email sent successfully!</span>
    </div>

    <table class="table mt-4">
      <thead>
        <tr>
          <th style="width: 1px">Action</th>
          <th style="width: 150px">Parameters</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <button :disabled="welcomeEmailSent" class="btn btn-primary btn-sm text-white" @click="sendWelcomeEmail">
              Send welcome email
            </button>
          </td>
          <td>
            <select v-model="lang" class="form-control">
              <option value="sk">Slovak</option>
              <option value="en">English</option>
              <option value="cs">Czech</option>
            </select>
          </td>
          <td>Sends a welcome email to the user containing the login details and their PIN.</td>
        </tr>
      </tbody>
    </table>
    <div class="loading-overlay" :style="{ display: loading ? 'block' : 'none' }">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import connector from '../../../api/connector';

export default {
  name: 'user-detail-actions-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      loading: false,
      welcomeEmailSent: false,
      lang: 'sk',
    };
  },
  methods: {
    sendWelcomeEmail() {
      this.$modal
        .confirm('Are you sure you want to send a welcome email to this user?')
        .then(() => {
          this.loading = true;
          return connector.post(`/admin/custom/send-welcome-email/${this.pk}/${this.lang}`);
        })
        .then(() => (this.welcomeEmailSent = true))
        .catch(() => this.$modal.alert('Failed to send the welcome email.'))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss">
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}
</style>
