import DataStorageFactory from '../abstract/data-storage-factory.js';
import IosVoipMessage from './ios-voip-message-class';

/** @extends DataStorageFactory<IosVoipMessage> */
class IosVoipMessageFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'iosVoipToken';
  getModelName = () => 'ios-voip-message';
  getPrettyName = () => 'iOS VOIP Message';
}

const iosVoipMessageFactory = new IosVoipMessageFactory(IosVoipMessage.prototype);

export default iosVoipMessageFactory;
