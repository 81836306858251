<template>
  <div class="building-tab">
    <h5 class="card-title">Building</h5>
    <p class="text-muted mb-4">The general info about the building.</p>
    <phillip-form form="building" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import buildingFactory from '../../../model/building/building-factory';

export default {
  name: 'building-detail-building-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setBuilding: 'building/form/setStorage',
    }),
  },
  created() {
    buildingFactory
      .pk(this.pk)
      .then((building) => this.setBuilding(building))
      .catch((err) => this.$modal.alert(err));
  },
};
</script>
