import home from './home';
import SipDoor from '../views/sip-door/SipDoor.vue';
import NewSipDoor from '../views/sip-door/NewSipDoor.vue';
import SipDoorDetail from '../views/sip-door/SipDoorDetail.vue';

const sipDoor = {
  path: '/sip-door',
  name: 'SIP Doors',
  component: SipDoor,
  meta: { parent: home },
};

const newSipDoor = {
  path: '/sip-door/new',
  name: 'New SIP Door',
  component: NewSipDoor,
  meta: { parent: sipDoor },
};

const sipDoorDetail = {
  path: '/sip-door/detail/:pk',
  name: 'SIP Door Detail',
  redirect: '/sip-door/detail/:pk/sip-door',
  component: SipDoorDetail,
  meta: { parent: sipDoor, noLink: true },
};

// Tabs
import SipDoorTab from '../views/sip-door/tabs/SipDoorTab.vue';
import ActionsTab from '../views/sip-door/tabs/ActionsTab.vue';
import DoorBellsTab from '../views/sip-door/tabs/DoorBellsTab.vue';
import LocationTab from '../views/sip-door/tabs/LocationTab.vue';
import WhitelistTab from '../views/sip-door/tabs/WhitelistTab.vue';
import BlacklistTab from '../views/sip-door/tabs/BlacklistTab.vue';
import UserAccessTab from '../views/sip-door/tabs/UserAccessTab.vue';
import UserGroupAccessTab from '../views/sip-door/tabs/UserGroupAccessTab.vue';

sipDoorDetail.children = [
  {
    path: '/sip-door/detail/:pk/sip-door',
    name: 'SIP Door',
    component: SipDoorTab,
    meta: { parent: sipDoorDetail },
  },
  {
    path: '/sip-door/detail/:pk/actions',
    name: 'Actions',
    component: ActionsTab,
    meta: { parent: sipDoorDetail },
  },
  {
    path: '/sip-door/detail/:pk/door-bells',
    name: 'Door Bells',
    component: DoorBellsTab,
    meta: { parent: sipDoorDetail },
  },
  {
    path: '/sip-door/detail/:pk/location',
    name: 'Zone',
    component: LocationTab,
    meta: { parent: sipDoorDetail },
  },
  {
    path: '/sip-door/detail/:pk/blacklist',
    name: 'Blacklist',
    component: BlacklistTab,
    meta: { parent: sipDoorDetail },
  },
  {
    path: '/sip-door/detail/:pk/whitelist',
    name: 'Whitelist',
    component: WhitelistTab,
    meta: { parent: sipDoorDetail },
  },
  {
    path: '/sip-door/detail/:pk/user-access',
    name: 'Users',
    component: UserAccessTab,
    meta: { parent: sipDoorDetail },
  },
  {
    path: '/sip-door/detail/:pk/user-group-access',
    name: 'Groups',
    component: UserGroupAccessTab,
    meta: { parent: sipDoorDetail },
  },
];

const sipDoorRoutes = [sipDoor, newSipDoor, sipDoorDetail];

export default sipDoorRoutes;
