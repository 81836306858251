<template>
  <div class="sessions-tab">
    <h5 class="card-title">Sessions</h5>
    <p class="text-muted mb-4">The active sessions of the user.</p>
    <phillip-table
      table="session"
      hide-filters
      hide-new-button
      hide-edit-button
      disable-detail
      empty-text="The user has no active sessions."
      :filters="{ 'userId.eq': pk }"
      :exclude-columns="['userId']"
    />
  </div>
</template>

<script>
export default {
  name: 'user-detail-sessions-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
