<template>
  <card
    class="page-license-plates"
    title="All license plates"
    description="The list of all license plates.<br>A new license plate can be created on the user's detail page."
  >
    <button class="btn btn-sm btn-primary text-white ml-3" slot="title-button" @click="exportData">Export</button>
    <phillip-table
      hide-new-button
      hide-delete-button
      edit-button-label="Owner's detail"
      :on-edit="userDetail"
      :on-detail="userDetail"
      table="license-plate"
      @filter="saveFilters"
    />
  </card>
</template>

<script>
import { mapMutations } from 'vuex';
import licensePlateFactory from '../../model/license-plate/license-plate-factory';

export default {
  name: 'page-license-plates',
  data() {
    return {
      filters: {},
    };
  },
  methods: {
    ...mapMutations({
      setLicensePlatesToExport: 'licensePlateExport/setLicensePlates',
    }),
    async exportData() {
      const licensePlates = await licensePlateFactory.fetch(this.filters);
      this.setLicensePlatesToExport(licensePlates);
    },
    userDetail(_, { userId }) {
      this.$router.push(`/user/detail/${userId}/license-plates`);
    },
    saveFilters(filters) {
      this.filters = filters;
    },
  },
};
</script>
