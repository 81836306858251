<template>
  <div>
    <p class="mb-3 mt--3 text-muted">The list of all Chastia sensors.</p>
    <phillip-table hide-new-button table="chastia-sensor" :filters="{ 'phillipImported.true': true }" />
  </div>
</template>

<script>
export default {
  name: 'chastia-sensor-list-chastia-sensors-tab',
};
</script>
