import home from './home';
import AppShortcut from '../views/app-shortcut/AppShortcut.vue';
import NewAppShortcut from '../views/app-shortcut/NewAppShortcut.vue';
import AppShortcutDetail from '../views/app-shortcut/AppShortcutDetail.vue';

const appShortcut = {
  path: '/app-shortcut',
  name: 'App Shortcuts',
  component: AppShortcut,
  meta: { parent: home },
};

const newAppShortcut = {
  path: '/app-shortcut/new',
  name: 'New App Shortcut',
  component: NewAppShortcut,
  meta: { parent: appShortcut },
};

const appShortcutDetail = {
  path: '/app-shortcut/detail/:pk',
  name: 'App Shortcut Detail',
  redirect: '/app-shortcut/detail/:pk/app-shortcut',
  component: AppShortcutDetail,
  meta: { parent: appShortcut, noLink: true },
};

// Tabs
import AppShortcutTab from '../views/app-shortcut/tabs/AppShortcutTab.vue';
import BuildingsTab from '../views/app-shortcut/tabs/BuildingsTab.vue';
import UsersTab from '../views/app-shortcut/tabs/UsersTab.vue';

appShortcutDetail.children = [
  {
    path: '/app-shortcut/detail/:pk/app-shortcut',
    name: 'App Shortcut',
    component: AppShortcutTab,
    meta: { parent: appShortcutDetail },
  },
  {
    path: '/app-shortcut/detail/:pk/buildings',
    name: 'Buildings',
    component: BuildingsTab,
    meta: { parent: appShortcutDetail },
  },
  {
    path: '/app-shortcut/detail/:pk/users',
    name: 'Users',
    component: UsersTab,
    meta: { parent: appShortcutDetail },
  },
];

const appShortcutRoutes = [appShortcut, newAppShortcut, appShortcutDetail];

export default appShortcutRoutes;
