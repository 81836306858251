<template>
  <div class="user-group-access-tab">
    <h4 class="card-title">Groups</h4>
    <p class="text-muted mb-1">The list of groups who can access the Chastia sensor.</p>
    <ul class="text-muted mb-4">
      <li>
        A group can access the Chastia sensor if it can access the parent device:
        <phillip-lazy-display
          style="display: inline-block"
          display="chastia-device"
          :loading="chastiaSensor == null"
          :pk="(chastiaSensor || {}).parent"
        />
      </li>
    </ul>
    <phillip-table
      table="user-group"
      hide-filters
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      empty-text="No group can access the Chastia sensor."
      :filters="{ 'chastiaSensorAccess.eq': pk, 'owner.null': true }"
    />
  </div>
</template>

<script>
import chastiaSensorFactory from '../../../../model/chastia-sensor/chastia-sensor-factory';

export default {
  name: 'chastia-sensor-detail-user-group-access-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      chastiaSensor: null,
    };
  },
  async created() {
    this.chastiaSensor = await chastiaSensorFactory.pk(this.pk);
  },
};
</script>
