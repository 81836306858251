import DataStorageFactory from '../abstract/data-storage-factory.js';
import ChastiaSensorMeasuringType from './chastia-sensor-measuring-type-class';

/** @extends DataStorageFactory<ChastiaSensorMeasuringType> */
class ChastiaSensorMeasuringTypeFactory extends DataStorageFactory {
  getPrimaryKey = () => 'measuringTypeId';
  getTitleKey = () => 'measuringTypeName';
  getModelName = () => 'chastia-sensor-measuring-type';
  getPrettyName = () => 'Chastia Sensor Measuring Type';
}

const chastiaSensorMeasuringTypeFactory = new ChastiaSensorMeasuringTypeFactory(ChastiaSensorMeasuringType.prototype);

export default chastiaSensorMeasuringTypeFactory;
