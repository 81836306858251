import DataStorageFactory from '../abstract/data-storage-factory.js';
import FirebaseMessage from './firebase-message-class';

/** @extends DataStorageFactory<FirebaseMessage> */
class FirebaseMessageFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'text';
  getModelName = () => 'firebase-message';
  getPrettyName = () => 'Firebase Message';
}

const firebaseMessageFactory = new FirebaseMessageFactory(FirebaseMessage.prototype);

export default firebaseMessageFactory;
