import home from './home';
import Session from '../views/session/Session.vue';
// import NewSession from '../views/session/NewSession.vue';
// import SessionDetail from '../views/session/SessionDetail.vue';

const session = {
  path: '/session',
  name: 'Sessions',
  component: Session,
  meta: { parent: home },
};

// const newSession = {
//   path: '/session/new',
//   name: 'New Session',
//   component: NewSession,
//   meta: { parent: session },
// };

// const sessionDetail = {
//   path: '/session/detail/:pk',
//   name: 'Session Detail',
//   redirect: '/session/detail/:pk/session',
//   component: SessionDetail,
//   meta: { parent: session, noLink: true },
// };

// Tabs
// import SessionTab from '../views/session/tabs/SessionTab.vue';
// import LocationTab from '../views/session/tabs/LocationTab.vue';
// import UserAccessTab from '../views/session/tabs/UserAccessTab.vue';

// sessionDetail.children = [
// {
//   path: '/session/detail/:pk/session',
//   name: 'Session',
//   component: SessionTab,
//   meta: { parent: sessionDetail },
// },
// {
//   path: '/session/detail/:pk/location',
//   name: 'Zone',
//   component: LocationTab,
//   meta: { parent: sessionDetail },
// },
// {
//   path: '/session/detail/:pk/user-access',
//   name: 'User Access',
//   component: UserAccessTab,
//   meta: { parent: sessionDetail },
// },
// ];

const sessionRoutes = [
  session,
  // newSession,
  // sessionDetail
];

export default sessionRoutes;
