<template>
  <div
    v-if="licensePlates !== null"
    class="form-modal modal fade show license-export-modal"
    tabindex="-1"
    role="dialog"
    style="padding-right: 12px; display: block"
    aria-modal="true"
  >
    <div class="modal-dialog" role="document" style="max-width: 800px">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="form-modal-title modal-title">Exporting license plates</h5>
          <button type="button" class="btn close" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="max-height: 56vh; overflow: auto">
          <!-- The content -->
          <table class="table table-sm">
            <thead>
              <tr>
                <th>License Plate</th>
                <th>Country</th>
                <th>User</th>
                <th style="width: 1px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="licensePlate in licensePlates" :key="licensePlate.id">
                <td>{{ licensePlate.code }}</td>
                <td>{{ licensePlate.country }}</td>
                <td>
                  <phillip-lazy-display no-link display="user" :pk="licensePlate.userId" />
                </td>
                <td>
                  <button class="btn btn-sm" @click="removeLicensePlate(licensePlate)">
                    <icon style="transform: scale(0.7)" icon="feather-trash" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer d-flex justify-content-between align-items-end">
          <div class="left">
            <p class="mb-2">
              <span>Add to export</span>
              <span
                class="d-inline-block px-2 footer-tab btn btn-sm ml-2"
                :class="additional === LICENSE_PLATE ? 'btn-primary text-white' : 'btn-light'"
                @click="additional = LICENSE_PLATE"
              >
                License plate
              </span>
              <span
                class="d-inline-block px-2 footer-tab btn btn-sm ml-2"
                :class="additional === USER ? 'btn-primary text-white' : 'btn-light'"
                @click="additional = USER"
              >
                User
              </span>
            </p>
            <div class="d-flex">
              <phillip-select
                :select="additional"
                style="min-width: 400px"
                :placeholder="`Add ${additional === USER ? 'user' : 'license plate'} to export`"
                v-model="selected"
              />
              <button
                class="btn btn-sm btn-primary text-white ml-1"
                :disabled="loading"
                @click="addSelectedLicensePlate"
              >
                Add
              </button>
            </div>
          </div>
          <div class="right">
            <button class="btn btn-sm btn-secondary text-white" @click="close">Close</button>
            <button
              form="phillip-form-modal"
              class="btn btn-sm btn-primary text-white ml-2"
              :disabled="loading"
              @click="exportLicensePlates"
            >
              Export {{ licensePlates.length }} records
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import licensePlateFactory from '../../model/license-plate/license-plate-factory';

const LICENSE_PLATE = 'license-plate';
const USER = 'user';

export default {
  name: 'license-plate-export-modal',
  computed: {
    ...mapGetters({
      licensePlates: 'licensePlateExport/getLicensePlates',
      csv: 'licensePlateExport/getCSV',
    }),
  },
  data() {
    return {
      loading: false,
      additional: LICENSE_PLATE,
      selected: null,
      LICENSE_PLATE,
      USER,
    };
  },
  methods: {
    ...mapMutations({
      setLicensePlates: 'licensePlateExport/setLicensePlates',
      addLicensePlate: 'licensePlateExport/addLicensePlate',
      removeLicensePlate: 'licensePlateExport/removeLicensePlate',
    }),
    close() {
      this.$modal
        .confirm('Any modifications made will be forgotten. Are you sure?')
        .then(() => this.setLicensePlates(null))
        .catch(() => {});
    },
    async addSelectedLicensePlate() {
      try {
        this.loading = true;

        if (this.additional === LICENSE_PLATE) {
          this.addLicensePlate(await licensePlateFactory.pk(this.selected));
        } else {
          const licensePlates = await licensePlateFactory.ofUser(this.selected);
          licensePlates.forEach((licensePlate) => this.addLicensePlate(licensePlate));
        }

        this.selected = null;
      } finally {
        this.loading = false;
      }
    },
    exportLicensePlates() {
      const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + this.csv);

      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'ecv-export.csv');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
  watch: {
    additional() {
      this.selected = null;
    },
  },
};
</script>

<style lang="scss">
.license-export-modal {
  .footer-tab {
    cursor: pointer;

    &.active {
      cursor: default;
      opacity: 1;
    }
  }
}
</style>
