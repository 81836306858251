<template>
  <div class="door-bells-tab-tab">
    <h5 class="card-title">Door bells</h5>
    <p class="text-muted mb-4">The door bells next to the SIP door.</p>
    <phillip-table
      table="door-bell"
      disable-detail
      hide-filters
      empty-text="The SIP door has no door bells."
      :filters="{ 'sipDoorId.eq': this.pk }"
      :on-new="newDoorBell"
      :on-edit="editDoorBell"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import doorBellFactory from '../../../model/door-bell/door-bell-factory';

export default {
  name: 'sip-door-detail-door-bells-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setDoorBell: 'doorBell/form/setStorage',
    }),
    newDoorBell() {
      this.setDoorBell(doorBellFactory.blank({ sipDoorId: this.pk }));
      this.$modal.openForm('doorBell');
    },
    async editDoorBell(pk) {
      this.setDoorBell(await doorBellFactory.pk(pk)); // todo: open loading modal
      this.$modal.openForm('doorBell');
    },
  },
};
</script>
