import home from './home';
import Faq from '../views/faq/Faq.vue';
import NewFaq from '../views/faq/NewFaq.vue';
import FaqDetail from '../views/faq/FaqDetail.vue';

const faq = {
  path: '/faq',
  name: 'Faqs',
  component: Faq,
  meta: { parent: home },
};

const newFaq = {
  path: '/faq/new',
  name: 'New FAQ',
  component: NewFaq,
  meta: { parent: faq },
};

const faqDetail = {
  path: '/faq/detail/:pk',
  name: 'FAQ Detail',
  redirect: '/faq/detail/:pk/faq',
  component: FaqDetail,
  meta: { parent: faq, noLink: true },
};

// Tabs
import FaqTab from '../views/faq/tabs/FaqTab.vue';
import BuildingsTab from '../views/faq/tabs/BuildingsTab.vue';
import UsersTab from '../views/faq/tabs/UsersTab.vue';

faqDetail.children = [
  {
    path: '/faq/detail/:pk/faq',
    name: 'FAQ',
    component: FaqTab,
    meta: { parent: faqDetail },
  },
  {
    path: '/faq/detail/:pk/buildings',
    name: 'Buildings',
    component: BuildingsTab,
    meta: { parent: faqDetail },
  },
  {
    path: '/faq/detail/:pk/users',
    name: 'Users',
    component: UsersTab,
    meta: { parent: faqDetail },
  },
];

const faqRoutes = [faq, newFaq, faqDetail];

export default faqRoutes;
