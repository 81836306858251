<template>
  <card title="All chastia devices">
    <tabs :titles="['Chastia Devices', 'Meter Types']" @tab="tabChange">
      <!-- Chastia Devices -->
      <tab-pane title="Chastia Devices">
        <p class="mb-3 mt--3 text-muted">The list of all Chastia meters.</p>
        <phillip-table
          hide-new-button
          hide-delete-button
          table="chastia-device"
          :filters="{ 'phillipImported.true': true }"
        />
      </tab-pane>

      <!-- Meter Types -->
      <tab-pane title="Meter Types">
        <p class="mb-3 mt--3 text-muted">The list of all meter types known by Phillip backend.</p>
        <phillip-table
          hide-new-button
          hide-delete-button
          hide-edit-button
          disable-detail
          table="chastia-device-meter-type"
        />
      </tab-pane>
    </tabs>
  </card>
</template>

<script>
import chastiaDeviceFactory from '../../model/chastia-device/chastia-device-factory';
import chastiaDeviceMeterTypeFactory from '../../model/chastia-device-meter-type/chastia-device-meter-type-factory';

export default {
  name: 'page-chastia-device',
  methods: {
    tabChange() {
      chastiaDeviceFactory.signalChange();
      chastiaDeviceMeterTypeFactory.signalChange();
    },
  },
};
</script>
