import home from './home';
import FirebaseMessage from '../views/firebase-message/FirebaseMessage.vue';
import FirebaseMessageDetail from '../views/firebase-message/FirebaseMessageDetail.vue';

const firebaseMessage = {
  path: '/firebase-message',
  name: 'FirebaseMessages',
  component: FirebaseMessage,
  meta: { parent: home },
};

const firebaseMessageDetail = {
  path: '/firebase-message/detail/:pk',
  name: 'Firebase Message Detail',
  redirect: '/firebase-message/detail/:pk/firebase-message',
  component: FirebaseMessageDetail,
  meta: { parent: firebaseMessage, noLink: true },
};

// Tabs
import FirebaseMessageTab from '../views/firebase-message/tabs/FirebaseMessageTab.vue';

firebaseMessageDetail.children = [
  {
    path: '/firebase-message/detail/:pk/firebase-message',
    name: 'Firebase Message',
    component: FirebaseMessageTab,
    meta: { parent: firebaseMessageDetail },
  },
];

const firebaseMessageRoutes = [firebaseMessage, firebaseMessageDetail];

export default firebaseMessageRoutes;
