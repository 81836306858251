<template>
  <card class="short page-chastia-device-detail">
    <!-- Problems -->
    <!-- <div v-if="hasProblems" class="alert alert-warning d-flex align-items-center">
      <icon icon="feather-alert-circle" />
      <div class="ml-3">
        <p v-if="problems.actions">The SIP door has no actions.</p>
        <p v-if="problems.location">The zone of the SIP door is not set.</p>
        <p v-if="problems.access">No user has access to the SIP door.</p>
      </div>
    </div> -->

    <!-- Navigation -->
    <nav class="card-nav mb-4" role="group">
      <router-link :to="t('chastia-device')">Device</router-link>
      <router-link :to="t('sensors')">Sensors</router-link>
      <router-link :to="t('location')">Zone</router-link>
      <div class="spacer"></div>
      <router-link :to="t('blacklist')">Blacklist</router-link>
      <router-link :to="t('whitelist')">Whitelist</router-link>
      <div class="spacer"></div>
      <router-link :to="t('user-group-access')">Groups</router-link>
      <router-link :to="t('user-access')">Users</router-link>
      <div class="spacer"></div>
      <!-- <router-link :to="t('raw')">Raw</router-link> -->
      <router-link :to="t('chastia-api')">Chastia API</router-link>
    </nav>

    <!-- Tab content -->
    <main class="pt-2">
      <router-view />
    </main>
  </card>
</template>

<script>
// import chastiaDeviceFactory from '../../model/chastia-device/chastia-device-factory';

export default {
  name: 'page-chastia-device-detail',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    // hasProblems() {
    //   return Object.values(this.problems).some(Boolean);
    // },
  },
  data() {
    return {
      // problems: {},
      // submitHandler: null,
    };
  },
  methods: {
    t(path) {
      return `/chastia-device/detail/${this.pk}/${path}`;
    },
    // async fetchProblems() {
    //   this.problems = await chastiaDeviceFactory.pkMock(this.pk).computeProblems();
    // },
  },
  created() {
    // todo: do this better
    // this.submitHandler = () => this.fetchProblems();
    // window.addEventListener('dataStorageFactory.signalChange', this.submitHandler);
    // this.fetchProblems();
  },
  beforeDestroy() {
    // window.removeEventListener('dataStorageFactory.signalChange', this.submitHandler);
  },
};
</script>
