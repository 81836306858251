<template>
  <div class="access-tab">
    <h5 class="card-title">FAQs</h5>
    <p class="text-muted mb-4">The FAQs visible to the users in the building.</p>
    <phillip-table
      table="faq"
      hide-new-button
      hide-edit-button
      delete-button-label="Remove FAQ"
      hide-filters
      empty-text="The building has no FAQs."
      :filters="{ 'building.eq': pk }"
      :on-delete="removeFaq"
    />
    <div class="mt-3 d-flex">
      <phillip-select style="min-width: 450px" select="faq" placeholder="Add FAQ to building" v-model="faq" />
      <button @click="addFaq" :disabled="faq === null" class="btn btn-sm btn-primary text-white ml-3">Add FAQ</button>
    </div>
  </div>
</template>

<script>
import buildingFactory from '../../../model/building/building-factory';
import faqFactory from '../../../model/faq/faq-factory';

export default {
  name: 'building-detail-faqs-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      faq: null,
    };
  },
  methods: {
    addFaq() {
      if (this.faq !== null) {
        buildingFactory
          .pkMock(this.pk)
          .addFaq(this.faq)
          .then(() => faqFactory.signalChange())
          .catch((e) => this.$modal.alert(e));
      }
    },
    removeFaq(faqId) {
      this.$modal
        .confirm(`Remove FAQ #${faqId} from building?`)
        .then(() => {
          buildingFactory
            .pkMock(this.pk)
            .deleteFaq(faqId)
            .then(() => faqFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
