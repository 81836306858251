<template>
  <card class="page-firebase-message" title="All firebase messages" description="The list of all firebase messages.">
    <phillip-table hide-new-button table="firebase-message" />
  </card>
</template>

<script>
export default {
  name: 'page-firebase-message',
};
</script>
