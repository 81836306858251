<template>
  <div class="row w-100 mx-0 auth-page">
    <div class="col-md-6 col-xl-4 mx-auto">
      <div class="card mb position-relative">
        <div class="row">
          <div class="col-md-12 pl-md-0">
            <div class="auth-form-wrapper px-4 py-5">
              <a href="#" class="noble-ui-logo d-block mb-2">Phillip <span>Admin</span></a>
              <h5 class="text-muted font-weight-normal mb-4">Your account is not protected by 2FA.</h5>
              <p class="my-4">
                2FA required to access the dashboard as an admin. Use an authenticator app like Google Authenticator or
                Authy to scan the QR code below. Enter the code generated by the app to finish the setup.
              </p>
              <!-- QR Code example -->
              <div class="d-flex justify-content-center">
                <canvas ref="canvas"></canvas>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <p class="secret">{{ secret }}</p>
              </div>
              <div>
                <label for="totp" class="mt-4">Enter the code from the authenticator app</label>
                <input
                  autofocus
                  type="text"
                  class="form-control"
                  id="totp"
                  placeholder="Code"
                  v-model="totp"
                  pattern="\d{6}"
                  minlength="6"
                  maxlength="6"
                />
              </div>
              <p v-if="success" class="text-success mt-2">2FA setup successful. Redirecting...</p>
              <p v-else-if="error" class="text-danger mt-2">Invalid code. Please try again.</p>
            </div>
          </div>
        </div>
        <div v-if="loading" class="loading-overlay position-absolute d-flex justify-content-center align-items-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import connector from '../api/connector';
import { toCanvas } from 'qrcode';
import sleep from '../utils/sleep';

export default {
  name: 'totp-page',
  data() {
    return {
      secret: '*'.repeat(16),
      uri: null,
      totp: '',
      loading: false,
      success: false,
      error: false,
    };
  },
  methods: {
    initTotp() {
      connector
        .post('/totp/init')
        .then(({ data }) => {
          this.secret = data.secret;
          this.uri = data.uri;
        })
        .catch((e) => this.$modal.alert(e));
    },
    setupTotp(totp) {
      this.loading = true;

      sleep(500)
        .then(() => connector.post('/totp/setup', { totp }))
        .then(() => (this.success = true))
        .catch(() => (this.error = true))
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    uri(uri) {
      if (uri) {
        toCanvas(this.$refs.canvas, uri, this.$modal.alertIfNotNull.bind(this));
      }
    },
    totp(totp) {
      if (/\d{6}$/.test(totp)) {
        this.setupTotp(totp);
      } else if (/[^\d]/.test(totp)) {
        this.totp = totp.replace(/[\D]/g, '');
      }
    },
    success(success) {
      if (success) {
        sleep(3000).then(() => this.$router.push('/'));
      }
    },
  },
  created() {
    this.initTotp();
  },
};
</script>

<style scoped>
.secret {
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
}
</style>
