import home from './home';
import ChastiaSensor from '../views/chastia-sensor/list/ChastiaSensor.vue';
import ChastiaSensorDetail from '../views/chastia-sensor/detail/ChastiaSensorDetail.vue';

// List
const chastiaSensor = {
  path: '/chastia-sensor',
  name: 'Chastia Sensors',
  redirect: '/chastia-sensor/chastia-sensors',
  component: ChastiaSensor,
  meta: { parent: home },
};

import ChastiaSensorsTabList from '../views/chastia-sensor/list/tabs/ChastiaSensorsTab.vue';
import MeasuringTypesTabList from '../views/chastia-sensor/list/tabs/MeasuringTypesTab.vue';
import FunctionsTabList from '../views/chastia-sensor/list/tabs/FunctionsTab.vue';
import ImportTabList from '../views/chastia-sensor/list/tabs/ImportTab.vue';

chastiaSensor.children = [
  {
    path: '/chastia-sensor/chastia-sensors',
    name: 'Chastia Sensors',
    component: ChastiaSensorsTabList,
    meta: { parent: chastiaSensor },
  },
  {
    path: '/chastia-sensor/measuring-types',
    name: 'Measuring Types',
    component: MeasuringTypesTabList,
    meta: { parent: chastiaSensor },
  },
  {
    path: '/chastia-sensor/functions',
    name: 'Measuring Types',
    component: FunctionsTabList,
    meta: { parent: chastiaSensor },
  },
  {
    path: '/chastia-sensor/import',
    name: 'Measuring Types',
    component: ImportTabList,
    meta: { parent: chastiaSensor },
  },
];

// Detail
const chastiaSensorDetail = {
  path: '/chastia-sensor/detail/:pk',
  name: 'Chastia Sensor Detail',
  redirect: '/chastia-sensor/detail/:pk/chastia-sensor',
  component: ChastiaSensorDetail,
  meta: { parent: chastiaSensor, noLink: true },
};

import ChastiaSensorTabDetail from '../views/chastia-sensor/detail/tabs/ChastiaSensorTab.vue';
import FunctionsTabDetail from '../views/chastia-sensor/detail/tabs/FunctionsTab.vue';
import UserGroupAccessTabDetail from '../views/chastia-sensor/detail/tabs/UserGroupAccessTab.vue';
import UserAccessTabDetail from '../views/chastia-sensor/detail/tabs/UserAccessTab.vue';
import MeasurementsTabDetail from '../views/chastia-sensor/detail/tabs/MeasurementsTab.vue';
// import RawTabDetail from '../views/chastia-sensor/detail/tabs/RawTab.vue';
import ChastiaApiTabDetail from '../views/chastia-sensor/detail/tabs/ChastiaApiTab.vue';

chastiaSensorDetail.children = [
  {
    path: '/chastia-sensor/detail/:pk/chastia-sensor',
    name: 'Chastia Sensor',
    component: ChastiaSensorTabDetail,
    meta: { parent: chastiaSensorDetail },
  },
  {
    path: '/chastia-sensor/detail/:pk/functions',
    name: 'Functions',
    component: FunctionsTabDetail,
    meta: { parent: chastiaSensorDetail },
  },
  {
    path: '/chastia-sensor/detail/:pk/user-group-access',
    name: 'Groups',
    component: UserGroupAccessTabDetail,
    meta: { parent: chastiaSensorDetail },
  },
  {
    path: '/chastia-sensor/detail/:pk/user-access',
    name: 'Users',
    component: UserAccessTabDetail,
    meta: { parent: chastiaSensorDetail },
  },
  {
    path: '/chastia-sensor/detail/:pk/measurements',
    name: 'Measurements',
    component: MeasurementsTabDetail,
    meta: { parent: chastiaSensorDetail },
  },
  // {
  //   path: '/chastia-sensor/detail/:pk/raw',
  //   name: 'Raw',
  //   component: RawTabDetail,
  //   meta: { parent: chastiaSensorDetail },
  // },
  {
    path: '/chastia-sensor/detail/:pk/chastia-api',
    name: 'Chastia API',
    component: ChastiaApiTabDetail,
    meta: { parent: chastiaSensorDetail },
  },
];

const chastiaSensorRoutes = [chastiaSensor, chastiaSensorDetail];

export default chastiaSensorRoutes;
