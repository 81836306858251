import DataStorageFactory from '../abstract/data-storage-factory.js';
import BillingAddress from './billing-address-class';

/** @extends DataStorageFactory<BillingAddress> */
class BillingAddressFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'street';
  getModelName = () => 'billing-address';
  getPrettyName = () => 'Billing Address';

  /**
   * Returns the billing address for the specified user
   */
  async forUser(userId) {
    const data = await this.fetch({ 'userId.eq': userId });

    if (data.length >= 1) {
      return data[0];
    }

    return this.blank({ userId });
  }
}

const billingAddressFactory = new BillingAddressFactory(BillingAddress.prototype);

export default billingAddressFactory;
