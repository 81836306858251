<template>
  <camera-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by building -->
      <div class="table-filter-row">
        <label for="camera-table-filter-building" class="table-filter-label">Filter by building</label>
        <phillip-select
          select="building"
          id="camera-table-filter-building"
          placeholder="All buildings"
          v-model="building"
        />
      </div>

      <!-- Filter by area -->
      <div class="table-filter-row">
        <label for="camera-table-filter-area" class="table-filter-label">Filter by zone</label>
        <phillip-select
          select="area"
          id="camera-table-filter-area"
          placeholder="All zones"
          v-model="area"
          :filters="{ 'buildingId.eq': building }"
        />
      </div>
    </template>
  </camera-table-base>
</template>

<script>
import CameraTableBase from './CameraTableBase';

export default {
  name: 'camera-table',
  components: {
    CameraTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.area) {
        filters['areaId.eq'] = this.area;
      }

      if (this.building) {
        filters['buildingId.eq'] = this.building;
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  data() {
    return {
      area: null,
      building: null,
    };
  },
  methods: {
    clearFilters() {
      this.area = null;
      this.building = null;
    },
  },
};
</script>
