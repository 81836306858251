/**
 * Retrieve an environment variable. A different default value can be specified
 * for each environment. A single default value can be specified for all
 * environments.
 *
 * @param {string} name
 * @param {{ development: *, test: *, production: * } | string} defaultValue
 *
 * @returns {string}
 */
export default function getenv(name, defaultValue) {
  const env = process.env.NODE_ENV;

  if (typeof defaultValue === 'string') {
    return defaultValue;
  }

  if (Object.prototype.hasOwnProperty.call(defaultValue, env)) {
    return defaultValue[env];
  }

  throw new Error(`Environment variable is not set: ${name}.`);
}
