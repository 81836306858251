import home from './home';
import IosVoipMessage from '../views/ios-voip-message/IosVoipMessage.vue';
import IosVoipMessageDetail from '../views/ios-voip-message/IosVoipMessageDetail.vue';

const iosVoipMessage = {
  path: '/ios-voip-message',
  name: 'IosVoipMessages',
  component: IosVoipMessage,
  meta: { parent: home },
};

const iosVoipMessageDetail = {
  path: '/ios-voip-message/detail/:pk',
  name: 'Firebase Message Detail',
  redirect: '/ios-voip-message/detail/:pk/ios-voip-message',
  component: IosVoipMessageDetail,
  meta: { parent: iosVoipMessage, noLink: true },
};

// Tabs
import IosVoipMessageTab from '../views/ios-voip-message/tabs/IosVoipMessageTab.vue';

iosVoipMessageDetail.children = [
  {
    path: '/ios-voip-message/detail/:pk/ios-voip-message',
    name: 'Firebase Message',
    component: IosVoipMessageTab,
    meta: { parent: iosVoipMessageDetail },
  },
];

const iosVoipMessageRoutes = [iosVoipMessage, iosVoipMessageDetail];

export default iosVoipMessageRoutes;
