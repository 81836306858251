import DataStorageFactory from '../abstract/data-storage-factory.js';
import ChastiaDevice from './chastia-device-class';

/** @extends DataStorageFactory<ChastiaDevice> */
class ChastiaDeviceFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'chastiaName';
  getModelName = () => 'chastia-device';
  getPrettyName = () => 'Chastia Device';
  getSingular = () => 'Chastia device';
  getPlural = () => 'Chastia devices';
  getIcon = () => 'feather-tool';
}

const chastiaDeviceFactory = new ChastiaDeviceFactory(ChastiaDevice.prototype);

export default chastiaDeviceFactory;
