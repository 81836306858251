<template>
  <card
    class="short"
    title="Building"
    description="The general info about the building. More settings will be available after the building is created."
  >
    <phillip-form form="building" />
  </card>
</template>

<script>
import { mapMutations } from 'vuex';
import buildingFactory from '../../model/building/building-factory';

export default {
  name: 'page-new-building',
  methods: {
    ...mapMutations({
      setBuilding: 'building/form/setStorage',
    }),
  },
  created() {
    this.setBuilding(buildingFactory.blank());
  },
  beforeDestroy() {
    this.setBuilding(null);
  },
};
</script>
