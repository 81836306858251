<template>
  <div class="areas-tab">
    <h5 class="card-title">Zones</h5>
    <p class="text-muted mb-4">The zones in the building.</p>
    <phillip-table
      table="area"
      hide-filters
      empty-text="No zones in the building."
      :filters="{ 'buildingId.eq': this.pk }"
      :on-new="newArea"
      :on-edit="editArea"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import areaFactory from '../../../model/area/area-factory';

export default {
  name: 'building-detail-areas-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setArea: 'area/form/setStorage',
    }),
    newArea() {
      this.setArea(areaFactory.blank({ buildingId: this.pk }));
      this.$modal.openForm('area');
    },
    async editArea(pk) {
      this.setArea(await areaFactory.pk(pk)); // todo: open loading modal
      this.$modal.openForm('area');
    },
  },
};
</script>
