<template>
  <div class="tabs">
    <nav v-if="titles.length > 1" class="tabs-navigation mb-4" role="group">
      <template v-for="(title, index) in titles">
        <button :key="index" type="button" :class="c(index)" @click="activeTab = index">
          {{ title }}
        </button>
      </template>
    </nav>
    <main class="tabs-inner pt-2">
      <slot></slot>
    </main>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  computed: {
    titles() {
      return this.tabs.map((tab) => tab.title);
    },
  },
  data() {
    return {
      tabs: [],
      activeTab: 0,
    };
  },
  methods: {
    c(index) {
      const color = index == this.activeTab ? 'primary text-white' : 'light';
      return `btn btn-sm mr-1 mb-1 btn-${color}`;
    },
    gatherTabs() {
      this.tabs.splice(0, this.tabs.length);

      this.$slots.default.forEach((slot) => {
        if (slot.componentInstance && slot.componentInstance.COMPONENT_TAB_PANE) {
          this.tabs.push(slot.componentInstance);
        }
      });
    },
    redraw() {
      this.tabs.forEach((tab) => (tab.$el.style.display = 'none'));

      if (this.activeTab in this.tabs) {
        this.tabs[this.activeTab].$el.style.display = '';
      }
    },
  },
  watch: {
    activeTab(newValue, oldValue) {
      this.redraw();
      this.$emit('tab', { before: oldValue, after: newValue });
    },
  },
  mounted() {
    this.gatherTabs();
    this.redraw();
  },
};
</script>
