<template>
  <div class="users-tab">
    <h5 class="card-title">Users</h5>
    <p class="text-muted mb-1">The users with access to the building.</p>
    <ul class="text-muted mb-4">
      <li>A user can access the building if he is a member of at least one group which can access the building.</li>
    </ul>
    <phillip-table
      table="user"
      hide-filters
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      empty-text="No user has access to the building."
      :filters="{ 'buildingAccess.eq': this.pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'building-detail-users-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
