<template>
  <card class="page-ios-voip-message" title="All iOS VOIP messages" description="The list of all iOS VOIP messages.">
    <phillip-table hide-new-button table="ios-voip-message" />
  </card>
</template>

<script>
export default {
  name: 'page-ios-voip-message',
};
</script>
