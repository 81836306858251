<template>
  <card class="page-sip-account" title="All RFID Cards" description="The list of all RFID Cards.">
    <new-rfid-button slot="title-button" class="ml-3" />
    <phillip-table table="rfid" hide-new-button />
  </card>
</template>

<script>
import NewRfidButton from '../../components/buttons/NewRfidButton';

export default {
  name: 'page-rfid',
  components: {
    NewRfidButton,
  },
};
</script>
