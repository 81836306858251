export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $modal() {
          return {
            confirm: (msg) => this.$store.dispatch('modal/confirm', msg),
            alert: (msg) => this.$store.dispatch('modal/alert', msg),
            alertIfNotNull: (msg) => this.$store.dispatch('modal/alertIfNotNull', msg),
            openForm: (model) => this.$store.dispatch('modal/openForm', model),
            close: () => this.$store.dispatch('modal/close'),
          };
        },
      },
    });
  },
};
