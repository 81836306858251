<template>
  <div class="article-tab">
    <h5 class="card-title">Article</h5>
    <p class="text-muted mb-4">The general info about the article.</p>
    <phillip-form form="article" />
  </div>
</template>

<script>
import articleFactory from '../../../model/article/article-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-article',
  methods: {
    ...mapMutations({
      setArticle: 'article/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    articleFactory
      .pk(this.pk)
      .then((article) => this.setArticle(article))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setArticle(null);
  },
};
</script>
