import DataStorageFactory from '../abstract/data-storage-factory.js';
import Faq from './faq-class';

/** @extends DataStorageFactory<Faq> */
class FaqFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'question';
  getModelName = () => 'faq';
  getPrettyName = () => 'FAQ';
}

const faqFactory = new FaqFactory(Faq.prototype);

export default faqFactory;
