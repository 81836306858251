import axios from 'axios';
import { API_BASE } from '../environment';
import session from './session';

export const connectorState = {
  lastRequest: null,
};

const connector = axios.create({
  timeout: 5000,
  baseURL: API_BASE,
});

connector.interceptors.request.use((cfg) => {
  connectorState.lastRequest = Date.now();

  cfg.headers.Authorization = `Bearer ${session.jwt}`;

  return cfg;
});

connector.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response.data.code === 4303 || err.response.data.code === 4302) {
      session.logout();
    }

    throw err;
  }
);

export default connector;
