<template>
  <card title="All FAQs" description="The list of all FAQs.">
    <new-faq-button slot="title-button" class="ml-3" />
    <phillip-table table="faq" hide-new-button />
  </card>
</template>

<script>
import NewFaqButton from '../../components/buttons/NewFaqButton';

export default {
  name: 'page-faq',
  components: {
    NewFaqButton,
  },
};
</script>
