<template>
  <component :is="`${form}-form`" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import AccessTokenForm from './AccessTokenForm';
import AppShortcutForm from './AppShortcutForm';
import AreaForm from './AreaForm';
import ArticleForm from './ArticleForm';
import BillingAddressForm from './BillingAddressForm';
import BuildingForm from './BuildingForm';
import CameraForm from './CameraForm';
import ChastiaDeviceForm from './ChastiaDeviceForm';
import ChastiaSensorForm from './ChastiaSensorForm';
import ControlledEntryForm from './ControlledEntryForm';
import DoorBellForm from './DoorBellForm';
import EmailMessageForm from './EmailMessageForm';
import FaqForm from './FaqForm';
import FirebaseMessageForm from './FirebaseMessageForm';
import IosVoipMessageForm from './IosVoipMessageForm';
import LicensePlateForm from './LicensePlateForm';
import RfidForm from './RfidForm';
import SipDoorForm from './SipDoorForm';
import SipDoorActionForm from './SipDoorActionForm';
import UserForm from './UserForm';
import UserGroupForm from './UserGroupForm';
import UserSipAccountForm from './UserSipAccountForm';

export default {
  name: 'phillip-form',
  props: {
    form: {
      type: String,
      required: true,
    },
  },
  components: {
    AccessTokenForm,
    AppShortcutForm,
    AreaForm,
    ArticleForm,
    BillingAddressForm,
    BuildingForm,
    CameraForm,
    ChastiaDeviceForm,
    ChastiaSensorForm,
    ControlledEntryForm,
    DoorBellForm,
    EmailMessageForm,
    FaqForm,
    FirebaseMessageForm,
    IosVoipMessageForm,
    LicensePlateForm,
    RfidForm,
    SipDoorForm,
    SipDoorActionForm,
    UserForm,
    UserGroupForm,
    UserSipAccountForm,
  },
};
</script>
