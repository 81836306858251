import DataStorageFactory from '../abstract/data-storage-factory.js';
import DoorBell from './door-bell-class';

/** @extends DataStorageFactory<DoorBell> */
class DoorBellFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'label';
  getModelName = () => 'door-bell';
  getPrettyName = () => 'Door bell';
}

const doorBellFactory = new DoorBellFactory(DoorBell.prototype);

export default doorBellFactory;
