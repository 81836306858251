<template>
  <div class="access-tab">
    <h5 class="card-title">Access Tree</h5>
    <p class="text-muted mb-4">The groups' access tree.</p>
    <not-implemented-banner />
  </div>
</template>

<script>
export default {
  name: 'user-detail-access-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
