<template>
  <card
    class="short page-new-faq"
    title="New FAQ"
    description="The general info about the FAQ. More settings will be available after the FAQ is created."
  >
    <phillip-form form="faq" />
  </card>
</template>

<script>
import faqFactory from '../../model/faq/faq-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-faq',
  methods: {
    ...mapMutations({
      setFaq: 'faq/form/setStorage',
    }),
  },
  created() {
    this.setFaq(faqFactory.blank());
  },
  beforeDestroy() {
    this.setFaq(null);
  },
};
</script>
