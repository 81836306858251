<template>
  <div class="raw-tab">
    <h4 class="card-title">Chastia API</h4>
    <p class="text-muted mb-1">The Chastia API endpoints. <br />Click on an endpoint to open it in the browser.</p>
    <div v-if="chastiaDevice" class="mt-4">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 1px">Endpoint</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a
                :href="getEndpointUrl(`Meters/Full/${chastiaDevice.chastiaId}`)"
                class="btn btn-primary btn-sm text-white"
                rel="external"
                target="_blank"
              >
                GET /Meters/Full/{{ chastiaDevice.chastiaId }}
              </a>
            </td>
            <td>Fetch the detailed data about this device.</td>
          </tr>
          <tr>
            <td>
              <a
                class="btn btn-primary btn-sm text-white"
                :href="getEndpointUrl(`SensorsByMeter/${chastiaDevice.chastiaId}`)"
                rel="external"
                target="_blank"
              >
                GET /SensorsByMeter/{{ chastiaDevice.chastiaId }}
              </a>
            </td>
            <td>Fetch a list of all sensors which are part of this device.</td>
          </tr>
          <tr>
            <td>
              <a
                class="btn btn-primary btn-sm text-white"
                :href="getEndpointUrl(`SensorsByMeter/Simple/${chastiaDevice.chastiaId}`)"
                rel="external"
                target="_blank"
              >
                GET /SensorsByMeter/Simple/{{ chastiaDevice.chastiaId }}
              </a>
            </td>
            <td>Fetch a list of all sensors which are part of this device - simplified.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else class="mt-3">Loading ...</p>
  </div>
</template>

<script>
import { CHASTIA_API_BASE } from '../../../environment';
import chastiaDeviceFactory from '../../../model/chastia-device/chastia-device-factory';

export default {
  name: 'chastia-device-detail-chastia-api-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      chastiaDevice: null,
    };
  },
  methods: {
    getEndpointUrl(endpoint) {
      return CHASTIA_API_BASE + endpoint;
    },
  },
  async created() {
    this.chastiaDevice = await chastiaDeviceFactory.pk(this.pk);
  },
};
</script>
