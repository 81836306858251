<template>
  <card title="All app shortcuts" description="The list of all app shortcuts.">
    <new-app-shortcut-button slot="title-button" class="ml-3" />
    <phillip-table hide-new-button table="app-shortcut" />
  </card>
</template>

<script>
import NewAppShortcutButton from '../../components/buttons/NewAppShortcutButton';

export default {
  name: 'page-app-shortcuts',
  components: {
    NewAppShortcutButton,
  },
};
</script>
