<template>
  <card
    class="short page-new-app-shortcut"
    title="New App Shortcut"
    description="The general info about the app shortcut. More settings will be available after the app shortcut is created."
  >
    <phillip-form form="app-shortcut" />
  </card>
</template>

<script>
import appShortcutFactory from '../../model/app-shortcut/app-shortcut-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-app-shortcut',
  methods: {
    ...mapMutations({
      setAppShortcut: 'appShortcut/form/setStorage',
    }),
  },
  created() {
    this.setAppShortcut(appShortcutFactory.blank());
  },
  beforeDestroy() {
    this.setAppShortcut(null);
  },
};
</script>
