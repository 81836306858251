<template>
  <div class="sip-doors-tab">
    <h5 class="card-title">SIP Doors</h5>
    <p class="text-muted mb-4">The SIP doors in the zone.</p>
    <area-devices-form :factory="factory" :area-id="pk" />
  </div>
</template>

<script>
import sipDoorFactory from '../../../model/sip-door/sip-door-factory';
import AreaDevicesForm from '../../../components/forms/AreaDevicesForm';

export default {
  name: 'area-detail-sip-doors-tab',
  components: {
    AreaDevicesForm,
  },
  computed: {
    pk() {
      return Number(this.$route.params.pk);
    },
  },
  data() {
    return {
      factory: sipDoorFactory,
    };
  },
};
</script>
