<template>
  <div class="ios-voip-message-tab">
    <h5 class="card-title">iOS VOIP Message</h5>
    <p class="text-muted mb-4">The general info about the iOS VOIP message.</p>
    <phillip-form form="ios-voip-message" />
  </div>
</template>

<script>
import iosVoipMessageFactory from '../../../model/ios-voip-message/ios-voip-message-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-ios-voip-message',
  methods: {
    ...mapMutations({
      setIosVoipMessage: 'iosVoipMessage/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    iosVoipMessageFactory
      .pk(this.pk)
      .then((iosVoipMessage) => this.setIosVoipMessage(iosVoipMessage))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setIosVoipMessage(null);
  },
};
</script>
