import DataStorageFactory from '../abstract/data-storage-factory.js';
import Rfid from './rfid-class';

/** @extends DataStorageFactory<Rfid> */
class RfidFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'label';
  getModelName = () => 'rfid';
  getPrettyName = () => 'RFID';
}

const rfidFactory = new RfidFactory(Rfid.prototype);

export default rfidFactory;
