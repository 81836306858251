<template>
  <div>
    <input
      class="form-control"
      v-model="valueStorage"
      v-bind="properties"
      type="text"
      :pattern="pattern"
      :readonly="!!readonly"
      :required="!!required"
    />
    <p v-if="note">
      <small class="text-muted">{{ note }}</small>
    </p>
  </div>
</template>

<script>
export default {
  name: 'text-input',
  props: ['value', 'properties', 'type', 'pattern', 'note', 'readonly', 'required'],
  data() {
    return {
      valueStorage: this.value,
    };
  },
  watch: {
    valueStorage() {
      this.$emit('input', this.valueStorage);
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = newValue;
      }
    },
  },
};
</script>
