import home from './home';
import Article from '../views/article/Article.vue';
import NewArticle from '../views/article/NewArticle.vue';
import ArticleDetail from '../views/article/ArticleDetail.vue';

const article = {
  path: '/article',
  name: 'Articles',
  component: Article,
  meta: { parent: home },
};

const newArticle = {
  path: '/article/new',
  name: 'New Article',
  component: NewArticle,
  meta: { parent: article },
};

const articleDetail = {
  path: '/article/detail/:pk',
  name: 'Article Detail',
  redirect: '/article/detail/:pk/article',
  component: ArticleDetail,
  meta: { parent: article, noLink: true },
};

// Tabs
import ArticleTab from '../views/article/tabs/ArticleTab.vue';
import BuildingsTab from '../views/article/tabs/BuildingsTab.vue';
import UsersTab from '../views/article/tabs/UsersTab.vue';

articleDetail.children = [
  {
    path: '/article/detail/:pk/article',
    name: 'Article',
    component: ArticleTab,
    meta: { parent: articleDetail },
  },
  {
    path: '/article/detail/:pk/buildings',
    name: 'Buildings',
    component: BuildingsTab,
    meta: { parent: articleDetail },
  },
  {
    path: '/article/detail/:pk/users',
    name: 'Users',
    component: UsersTab,
    meta: { parent: articleDetail },
  },
];

const articleRoutes = [article, newArticle, articleDetail];

export default articleRoutes;
