<template>
  <firebase-message-table-base
    v-bind="$attrs"
    :filters="_filters"
    @clear="clearFilters"
    hide-delete-button
    edit-button-label="Detail"
  >
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by user -->
      <div class="table-filter-row">
        <label for="user-table-filter-user" class="table-filter-label"> Filter by user </label>
        <phillip-select select="user" id="user-table-filter-user" placeholder="All users" v-model="user" />
      </div>
    </template>
  </firebase-message-table-base>
</template>

<script>
import FirebaseMessageTableBase from './FirebaseMessageTableBase';

export default {
  name: 'firebase-message-table',
  components: {
    FirebaseMessageTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.user) {
        filters['userId.eq'] = this.user;
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      this.user = null;
    },
  },
  data() {
    return {
      user: null,
    };
  },
};
</script>
