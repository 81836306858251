import ApiPlayground from '../views/debug/ApiPlayground.vue';
import home from './home';

const apiPlayground = {
  path: '/api-playground',
  name: 'Home',
  component: ApiPlayground,
  meta: { parent: home },
};

export default apiPlayground;
