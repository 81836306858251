<template>
  <div class="user-tab">
    <h5 class="card-title">User</h5>
    <p class="text-muted mb-4">The general info about the user.</p>
    <phillip-form form="user" />
  </div>
</template>

<script>
import userFactory from '../../../model/user/user-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'user-detail-user-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setUser: 'user/form/setStorage',
    }),
    fetchData() {
      userFactory
        .pk(this.pk)
        .then((user) => this.setUser(user))
        .catch((err) => this.$modal.alert(err));
    },
  },
  watch: {
    pk: 'fetchData',
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    this.setUser(null);
  },
};
</script>
