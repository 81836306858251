import DataStorageFactory from '../abstract/data-storage-factory.js';
import Session from './session-class';

/** @extends DataStorageFactory<Session> */
class SessionFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'title';
  getModelName = () => 'session';
  getPrettyName = () => 'Session';
}

const sessionFactory = new SessionFactory(Session.prototype);

export default sessionFactory;
