import DataStorageFactory from '../abstract/data-storage-factory.js';
import UserGroup from './user-group-class';

/** @extends DataStorageFactory<UserGroup> */
class UserGroupFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'name';
  getModelName = () => 'user-group';
  getPrettyName = () => 'User Group';
  getSingular = () => 'Group';
  getPlural = () => 'Groups';
  getIcon = () => 'feather-users';
}

const userGroupFactory = new UserGroupFactory(UserGroup.prototype);

export default userGroupFactory;
