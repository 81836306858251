<template>
  <div class="location-tab">
    <h5 class="card-title">Zone</h5>
    <p class="text-muted mb-4">The zone of the camera.</p>
    <location-form v-if="camera" :storage="camera" />
  </div>
</template>

<script>
import LocationForm from '../../../components/forms/LocationForm';
import cameraFactory from '../../../model/camera/camera-factory';

export default {
  name: 'camera-detail-location-tab',
  components: {
    LocationForm,
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      camera: null,
    };
  },
  created() {
    this.camera = cameraFactory.pkMock(this.pk);
  },
};
</script>
