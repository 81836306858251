<template>
  <card class="short page-email-message-detail">
    <!-- Navigation -->
    <nav class="card-nav mb-4" role="group">
      <router-link :to="t('email-message')">Email Message</router-link>
    </nav>

    <!-- Tab content -->
    <main class="pt-2">
      <router-view />
    </main>
  </card>
</template>

<script>
export default {
  name: 'page-email-message-detail',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    t(path) {
      return `/email-message/detail/${this.pk}/${path}`;
    },
  },
};
</script>
