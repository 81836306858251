<template>
  <card
    class="short page-new-article"
    title="New Article"
    description="The general info about the article. More settings will be available after the article is created."
  >
    <phillip-form form="article" />
  </card>
</template>

<script>
import { mapMutations } from 'vuex';
import articleFactory from '../../model/article/article-factory';

export default {
  name: 'page-new-article',
  methods: {
    ...mapMutations({
      setArticle: 'article/form/setStorage',
    }),
  },
  created() {
    this.setArticle(articleFactory.blank());
  },
  beforeDestroy() {
    this.setArticle(null);
  },
};
</script>
