<template>
  <div class="sensors-tab">
    <h5 class="card-title">Sensors</h5>
    <p class="text-muted mb-4">The sensors which part of the Chastia device.</p>
    <phillip-table
      table="chastia-sensor"
      hide-filters
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      empty-text="The device has no sensors."
      :filters="{ 'parent.eq': this.pk, 'phillipImported.true': true }"
    />
  </div>
</template>

<script>
export default {
  name: 'chastia-device-detail-sensors-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
