import DataStorageFactory from '../abstract/data-storage-factory.js';
import Article from './article-class';

/** @extends DataStorageFactory<Article> */
class ArticleFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'title';
  getModelName = () => 'article';
  getPrettyName = () => 'Article';
}

const articleFactory = new ArticleFactory(Article.prototype);

export default articleFactory;
