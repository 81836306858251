import DataStorageFactory from '../abstract/data-storage-factory.js';
import EmailMessage from './email-message-class';

/** @extends DataStorageFactory<EmailMessage> */
class EmailMessageFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'subject';
  getModelName = () => 'email-message';
  getPrettyName = () => 'Email Message';
}

const emailMessageFactory = new EmailMessageFactory(EmailMessage.prototype);

export default emailMessageFactory;
