<template>
  <form class="forms-sample" @submit.prevent="changePassword">
    <p class="text-muted mb-4">Please, choose your new password.</p>
    <div class="form-group mb-3">
      <label for="password">
        New password
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <input required v-model="password" type="password" class="form-control" id="password" />
    </div>
    <div class="form-group mb-3">
      <label for="password">
        Repeat new password
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <input required v-model="passwordRepeat" type="password" class="form-control" id="password" />
    </div>
    <div class="mt-4 d-flex align-items-center">
      <button type="submit" class="btn btn-primary btn-sm text-white">Submit</button>
      <router-link to="/login" class="text-muted ml-4">Cancel</router-link>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import connector from '../../../api/connector';

export default {
  name: 'reset-password-step-3',
  computed: {
    ...mapGetters({
      token: 'resetPassword/getToken',
      code: 'resetPassword/getCode',
    }),
  },
  data() {
    return {
      password: '',
      passwordRepeat: '',
    };
  },
  methods: {
    ...mapMutations({
      resetToken: 'resetPassword/resetToken',
      resetCode: 'resetPassword/resetCode',
    }),
    changePassword() {
      if (this.password !== this.passwordRepeat) {
        return this.$modal.alert('The passwords do not match');
      }

      connector
        .post('/change-password', { password: this.password, token: this.token, code: this.code })
        .then(() => this.$router.push('/login'))
        .catch((e) => this.$modal.alert(e));
    },
  },
  created() {
    if (!this.token) {
      this.$router.push('/reset-password/step/1');
    }
  },
  beforeDestroy() {
    this.resetCode();
    this.resetToken();
  },
};
</script>
