import home from './home';
import LicensePlate from '../views/license-plate/LicensePlate.vue';
// import NewLicensePlate from '../views/license-plate/NewLicensePlate.vue';
// import LicensePlateDetail from '../views/license-plate/LicensePlateDetail.vue';

const licensePlate = {
  path: '/license-plate',
  name: 'License Plates',
  component: LicensePlate,
  meta: { parent: home },
};

// const newLicensePlate = {
//   path: '/license-plate/new',
//   name: 'New License Plate',
//   component: NewLicensePlate,
//   meta: { parent: licensePlate },
// };

// const licensePlateDetail = {
//   path: '/license-plate/detail/:pk',
//   name: 'License Plate Detail',
//   redirect: '/license-plate/detail/:pk/license-plate',
//   component: LicensePlateDetail,
//   meta: { parent: licensePlate, noLink: true },
// };

// Tabs
// import LicensePlateTab from '../views/license-plate/tabs/LicensePlateTab.vue';
// import LocationTab from '../views/license-plate/tabs/LocationTab.vue';
// import UserAccessTab from '../views/license-plate/tabs/UserAccessTab.vue';

// licensePlateDetail.children = [
// {
//   path: '/license-plate/detail/:pk/license-plate',
//   name: 'License Plate',
//   component: LicensePlateTab,
//   meta: { parent: licensePlateDetail },
// },
// {
//   path: '/license-plate/detail/:pk/location',
//   name: 'Zone',
//   component: LocationTab,
//   meta: { parent: licensePlateDetail },
// },
// {
//   path: '/license-plate/detail/:pk/user-access',
//   name: 'User Access',
//   component: UserAccessTab,
//   meta: { parent: licensePlateDetail },
// },
// ];

const licensePlateRoutes = [
  licensePlate,
  // newLicensePlate,
  // licensePlateDetail
];

export default licensePlateRoutes;
