<template>
  <div class="members-tab">
    <h5 class="card-title">Groups</h5>
    <p class="text-muted mb-4">The groups this user is a member of.</p>
    <phillip-table
      table="user-group"
      hide-new-button
      hide-edit-button
      hide-filters
      delete-button-label="Remove from group"
      empty-text="The user is part of no groups."
      :filters="{ 'userId.eq': pk, 'owner.null': true }"
      :on-delete="removeFromGroup"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="user-group"
        placeholder="Add user to group"
        v-model="userGroup"
        :filters="{ 'owner.null': true }"
      />
      <button @click="addToGroup" :disabled="userGroup === null" class="btn btn-sm btn-primary text-white ml-3">
        Add to group
      </button>
    </div>
  </div>
</template>

<script>
import userFactory from '../../../model/user/user-factory';
import userGroupFactory from '../../../model/user-group/user-group-factory';

export default {
  name: 'user-detail-user-groups-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    storage() {
      return userFactory.pkMock(this.pk);
    },
  },
  data() {
    return {
      userGroup: null,
    };
  },
  methods: {
    addToGroup() {
      this.storage
        .addGroup(this.userGroup)
        .then(() => {
          this.userGroup = null;
          userGroupFactory.signalChange();
        })
        .catch((e) => this.$modal.alert(e));
    },
    removeFromGroup(groupId) {
      this.$modal
        .confirm(`Delete user from group ${groupId}?`)
        .then(() => {
          this.storage
            .deleteGroup(groupId)
            .then(() => userGroupFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
