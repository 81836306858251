<template>
  <card
    class="short"
    title="New User Group"
    description="The general info about the user group. More settings will be available after the user group is created."
  >
    <phillip-form form="user-group" />
  </card>
</template>

<script>
import userGroupFactory from '../../model/user-group/user-group-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-user',
  methods: {
    ...mapMutations({
      setUserGroup: 'userGroup/form/setStorage',
    }),
  },
  created() {
    this.setUserGroup(userGroupFactory.blank());
  },
  beforeDestroy() {
    this.setUserGroup(null);
  },
};
</script>
