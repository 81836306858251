import DataStorageFactory from '../abstract/data-storage-factory.js';
import ChastiaDeviceMeterType from './chastia-device-meter-type-class';

/** @extends DataStorageFactory<ChastiaDeviceMeterType> */
class ChastiaDeviceMeterTypeFactory extends DataStorageFactory {
  getPrimaryKey = () => 'meterTypeId';
  getTitleKey = () => 'meterTypeName';
  getModelName = () => 'chastia-device-meter-type';
  getPrettyName = () => 'Meter Type';
}

const chastiaDeviceMeterTypeFactory = new ChastiaDeviceMeterTypeFactory(ChastiaDeviceMeterType.prototype);

export default chastiaDeviceMeterTypeFactory;
