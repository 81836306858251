<template>
  <card title="All Groups" description="The list of all user groups.">
    <new-user-group-button slot="title-button" class="ml-3" />
    <phillip-table hide-new-button table="user-group" :filters="{ 'owner.null': true }" />
  </card>
</template>

<script>
import NewUserGroupButton from '../../components/buttons/NewUserGroupButton';

export default {
  name: 'page-user-group',
  components: {
    NewUserGroupButton,
  },
};
</script>
