import DataStorageFactory from '../abstract/data-storage-factory.js';
import ChastiaSensorMeasurement from './chastia-sensor-measurement-class';

/** @extends DataStorageFactory<ChastiaSensorMeasurement> */
class ChastiaSensorMeasurementFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'timestamp';
  getModelName = () => 'chastia-sensor-measurement';
  getPrettyName = () => 'Measurement';
}

const chastiaSensorMeasurementFactory = new ChastiaSensorMeasurementFactory(ChastiaSensorMeasurement.prototype);

export default chastiaSensorMeasurementFactory;
