import home from './home';
import UserGroup from '../views/user-group/UserGroup.vue';
import NewUserGroup from '../views/user-group/NewUserGroup.vue';
import UserGroupDetail from '../views/user-group/UserGroupDetail.vue';

const userGroup = {
  path: '/user-group',
  name: 'Groups',
  component: UserGroup,
  meta: { parent: home },
};

const newUserGroup = {
  path: '/user-group/new',
  name: 'New Group',
  component: NewUserGroup,
  meta: { parent: userGroup },
};

const userGroupDetail = {
  path: '/user-group/detail/:pk',
  name: 'Group Detail',
  redirect: '/user-group/detail/:pk/user-group',
  component: UserGroupDetail,
  meta: { parent: userGroup, noLink: true },
};

// Tabs
import UserGroupTab from '../views/user-group/tabs/UserGroupTab.vue';
import MembersTab from '../views/user-group/tabs/MembersTab.vue';
import ZonesTab from '../views/user-group/tabs/ZonesTab.vue';
import AccessTab from '../views/user-group/tabs/AccessTab.vue';
import SipDoorsTab from '../views/user-group/tabs/SipDoorsTab.vue';
import ControlledEntriesTab from '../views/user-group/tabs/ControlledEntriesTab.vue';
import CamerasTab from '../views/user-group/tabs/CamerasTab.vue';

userGroupDetail.children = [
  {
    path: '/user-group/detail/:pk/user-group',
    name: 'Group',
    component: UserGroupTab,
    meta: { parent: userGroupDetail },
  },
  {
    path: '/user-group/detail/:pk/members',
    name: 'Group Members',
    component: MembersTab,
    meta: { parent: userGroupDetail },
  },
  {
    path: '/user-group/detail/:pk/zones',
    name: 'Zones',
    component: ZonesTab,
    meta: { parent: userGroupDetail },
  },
  {
    path: '/user-group/detail/:pk/access',
    name: 'Access',
    component: AccessTab,
    meta: { parent: userGroupDetail },
  },
  {
    path: '/user-group/detail/:pk/sip-doors',
    name: 'SIP Doors',
    component: SipDoorsTab,
    meta: { parent: userGroupDetail },
  },
  {
    path: '/user-group/detail/:pk/controlled-entries',
    name: 'Controlled Entries',
    component: ControlledEntriesTab,
    meta: { parent: userGroupDetail },
  },
  {
    path: '/user-group/detail/:pk/cameras',
    name: 'Cameras',
    component: CamerasTab,
    meta: { parent: userGroupDetail },
  },
];

const userGroupRoutes = [userGroup, newUserGroup, userGroupDetail];

export default userGroupRoutes;
