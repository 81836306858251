<template>
  <form class="form" @submit.prevent="changePassword">
    <p class="text-muted mb-4">Please, choose your new password.</p>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">
        Email
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <div class="col-sm-9">
        <input required readonly type="email" class="form-control" :value="session.email" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">
        New password
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <div class="col-sm-9">
        <input required type="password" class="form-control" v-model="password" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">
        Repeat new password
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <div class="col-sm-9">
        <input required type="password" class="form-control" v-model="passwordRepeat" />
      </div>
    </div>
    <button type="submit" class="btn btn-primary btn-sm text-white">Submit</button>
  </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import connector from '../../../api/connector';
import session from '../../../api/session';

export default {
  name: 'change-password-step-3',
  computed: {
    ...mapGetters({
      token: 'changePassword/getToken',
      code: 'changePassword/getCode',
    }),
  },
  data() {
    return {
      password: '',
      passwordRepeat: '',
      session,
    };
  },
  methods: {
    ...mapMutations({
      resetToken: 'changePassword/resetToken',
      resetCode: 'changePassword/resetCode',
    }),
    changePassword() {
      if (this.password !== this.passwordRepeat) {
        return this.$modal.alert('The passwords do not match');
      }

      connector
        .post('/change-password', { password: this.password, token: this.token, code: this.code })
        .then(() => session.logout())
        .catch((e) => this.$modal.alert(e));
    },
  },
  created() {
    if (!this.token) {
      this.$router.push('/change-password/step/1');
    }
  },
  beforeDestroy() {
    this.resetCode();
    this.resetToken();
  },
};
</script>
