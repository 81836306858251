<template>
  <div class="controlled-entry-tab">
    <h5 class="card-title">Controlled Entry</h5>
    <p class="text-muted mb-4">The general info about the controlled entry.</p>
    <phillip-form form="controlled-entry" />
  </div>
</template>

<script>
import controlledEntryFactory from '../../../model/controlled-entry/controlled-entry-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-controlled-entry',
  methods: {
    ...mapMutations({
      setControlledEntry: 'controlledEntry/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    controlledEntryFactory
      .pk(this.pk)
      .then((controlledEntry) => this.setControlledEntry(controlledEntry))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setControlledEntry(null);
  },
};
</script>
