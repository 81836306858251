import DataStorageFactory from '../abstract/data-storage-factory.js';
import Camera from './camera-class';

/** @extends DataStorageFactory<Camera> */
class CameraFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'name';
  getModelName = () => 'camera';
  getPrettyName = () => 'Camera';
  getSingular = () => 'camera';
  getPlural = () => 'cameras';
  getIcon = () => 'feather-camera';
}

const cameraFactory = new CameraFactory(Camera.prototype);

export default cameraFactory;
