<template>
  <span :class="className" @click="toggle">{{ text }}</span>
</template>

<script>
export default {
  name: 'spoiler',
  props: {
    value: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: Infinity,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    className() {
      return ['spoiler', this.visible ? 'spoiler--visible' : 'spoiler--hidden'];
    },
    placeholderLength() {
      return Math.min(this.value.length, this.maxLength);
    },
    placeholder() {
      return '*'.repeat(this.placeholderLength).trimEnd();
    },
    text() {
      return this.visible ? this.value : this.placeholder;
    },
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
  },
};
</script>

<style scoped>
.spoiler {
  cursor: pointer;
  color: #2030e2;
  font-weight: bold;
}

.spoiler--visible {
  letter-spacing: 0.01em;
}

.spoiler--hidden {
  letter-spacing: 0.15em;
}
</style>
