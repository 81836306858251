<template>
  <div class="access-tab">
    <h5 class="card-title">Access Tree</h5>
    <p class="text-muted mb-4">The user's access tree.</p>
    <not-implemented-banner />
  </div>
</template>

<script>
import userFactory from '../../../model/user/user-factory';
import userGroupFactory from '../../../model/user-group/user-group-factory';
import buildingFactory from '../../../model/building/building-factory';
import areaFactory from '../../../model/area/area-factory';
import connector from '../../../api/connector';

export default {
  name: 'user-detail-access-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      user: null,
      groups: null,
      buildings: null,
      areas: null,
    };
  },
  async created() {
    const [user, groups, buildings, areas] = await Promise.all([
      userFactory.pk(this.pk),
      userGroupFactory.fetch({ 'userId.eq': this.pk }),
      buildingFactory.fetch({ 'userAccess.eq': this.pk }),
      areaFactory.fetch({ 'userAccess.eq': this.pk }),
    ]);

    this.user = user;
    this.groups = groups;
    this.buildings = buildings;
    this.areas = areas;

    const { data } = await connector.get('/admin/access-tree/' + this.pk);
    console.log(data);
  },
};
</script>

<style lang="scss"></style>
