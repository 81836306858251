<template>
  <div class="license-plates-tab">
    <h5 class="card-title">License Plates</h5>
    <p class="text-muted mb-4">The user's registered license plates.</p>
    <phillip-table
      table="license-plate"
      disable-detail
      hide-filters
      empty-text="The user has no registered license plates."
      :filters="{ 'userId.eq': pk }"
      :on-new="newLicensePlate"
      :on-edit="editLicensePlate"
      :exclude-columns="['userId']"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import licensePlateFactory from '../../../model/license-plate/license-plate-factory';

export default {
  name: 'user-detail-license-plates-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setLicensePlate: 'licensePlate/form/setStorage',
    }),
    newLicensePlate() {
      this.setLicensePlate(licensePlateFactory.blank({ userId: this.pk }));
      this.$modal.openForm('licensePlate');
    },
    async editLicensePlate(pk) {
      this.setLicensePlate(await licensePlateFactory.pk(pk)); // todo: open loading modal
      this.$modal.openForm('licensePlate');
    },
  },
};
</script>
