<template>
  <div class="controlled-entries-tab">
    <h5 class="card-title">Controlled entries</h5>
    <p class="text-muted mb-4">The controlled entries in the zone.</p>
    <area-devices-form :factory="factory" :area-id="pk" />
  </div>
</template>

<script>
import controlledEntryFactory from '../../../model/controlled-entry/controlled-entry-factory';
import AreaDevicesForm from '../../../components/forms/AreaDevicesForm';

export default {
  name: 'area-detail-controlled-entries-tab',
  components: {
    AreaDevicesForm,
  },
  computed: {
    pk() {
      return Number(this.$route.params.pk);
    },
  },
  data() {
    return {
      factory: controlledEntryFactory,
    };
  },
};
</script>
