<template>
  <user-group-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters">
      <div class="spacer"></div>

      <!-- Filter by user -->
      <div class="table-filter-row">
        <label for="user-group-table-filter-user" class="table-filter-label"> Filter by user </label>
        <phillip-select select="user" id="user-group-table-filter-user" placeholder="All users" v-model="user" />
      </div>

      <div class="spacer"></div>

      <!-- Filter by area -->
      <div class="table-filter-row">
        <label for="user-table-filter-area" class="table-filter-label">Filter by zone access</label>
        <phillip-select select="area" id="user-table-filter-area" placeholder="All zones" v-model="area" />
      </div>
    </template>
  </user-group-table-base>
</template>

<script>
import UserGroupTableBase from './UserGroupTableBase';

export default {
  name: 'user-group-table',
  components: {
    UserGroupTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      if (this.user !== null) {
        filters['userId.eq'] = this.user;
      }

      if (this.area) {
        filters['areaAccess.eq'] = this.area;
      }

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  data() {
    return {
      user: null,
      area: null,
    };
  },
  methods: {
    clearFilters() {
      this.user = null;
      this.area = null;
    },
  },
};
</script>
