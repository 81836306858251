<template>
  <form class="form" @submit.prevent="nextStep">
    <p class="text-muted mb-4">
      Please, enter the validation code which you have received in an email sent to
      <u>{{ session.email }}</u
      >.
    </p>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">
        Code
        <abbr class="text-danger" title="This field is required">*</abbr>
      </label>
      <div class="col-sm-9">
        <input required type="text" class="form-control" v-model="code" />
      </div>
    </div>
    <button type="submit" class="btn btn-primary btn-sm text-white">Submit</button>
  </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import connector from '../../../api/connector';
import session from '../../../api/session';

export default {
  name: 'change-password-step-2',
  computed: {
    ...mapGetters({
      token: 'changePassword/getToken',
    }),
  },
  data() {
    return {
      session,
      code: '',
    };
  },
  methods: {
    ...mapMutations({
      setCode: 'changePassword/setCode',
      resetCode: 'changePassword/resetCode',
    }),
    nextStep() {
      connector
        .put('/access-token/validate', { token: this.token, code: this.code })
        .then(() => {
          this.setCode(this.code);
          this.$router.push('/change-password/step/3');
        })
        .catch(() => this.$modal.alert('The code is invalid or expired'));
    },
  },
  created() {
    this.resetCode();

    if (!this.token) {
      this.$router.push('/change-password/step/1');
    }
  },
};
</script>
