<template>
  <form id="dashboard-layout-search" class="search-form" @submit.prevent>
    <div class="input-group">
      <div class="input-group-prepend d-flex align-items-center">
        <div class="input-group-text">
          <icon icon="feather-search" />
        </div>
      </div>
      <input
        ref="input"
        type="search"
        class="form-control"
        placeholder="Search here..."
        v-model="q"
        @focus="(visible = true), (focus = true)"
        @blur="focus = false"
      />
    </div>

    <div class="search-results" v-show="visible">
      <div class="d-flex justify-content-between">
        <h5 class="text-muted font-weight-normal">
          <div v-if="loading">Loading...</div>
          <div v-else-if="results">
            Found {{ results.total }}
            <template v-if="results.total > 1">records</template>
            <template v-else>record</template>
            matching &bdquo;{{ q }}&rdquo;
          </div>
          <div v-else>Please, enter at least 3 characters...</div>
        </h5>
      </div>

      <div class="mt-4" v-if="results && results.storages">
        <!-- Users -->
        <results-section :q="lastSearchQuery" :results="results.storages.user" :factory="userFactory" />

        <!-- Groups -->
        <results-section :q="lastSearchQuery" :results="results.storages.userGroup" :factory="userGroupFactory" />

        <!-- Buildings -->
        <results-section :q="lastSearchQuery" :results="results.storages.building" :factory="buildingFactory" />

        <!-- Areas -->
        <results-section :q="lastSearchQuery" :results="results.storages.area" :factory="areaFactory" />

        <!-- Cameras -->
        <results-section :q="lastSearchQuery" :results="results.storages.camera" :factory="cameraFactory" />

        <!-- SIP doors -->
        <results-section :q="lastSearchQuery" :results="results.storages.sipDoor" :factory="sipDoorFactory" />

        <!-- Controlled entries -->
        <results-section
          :q="lastSearchQuery"
          :results="results.storages.controlledEntry"
          :factory="controlledEntryFactory"
        />

        <!-- Chastia devices -->
        <results-section
          :q="lastSearchQuery"
          :results="results.storages.chastiaDevice"
          :factory="chastiaDeviceFactory"
        />

        <!-- Chastia sensors -->
        <results-section
          :q="lastSearchQuery"
          :results="results.storages.chastiaSensor"
          :factory="chastiaSensorFactory"
        />

        <!-- License plates -->
        <results-section
          :q="lastSearchQuery"
          :results="results.storages.licensePlate"
          :factory="licensePlateFactory"
          no-link
        />
      </div>
    </div>
  </form>
</template>

<script>
// todo: `goto: PAGE` shortcut for router

import connector from '../../../api/connector';
import { isObject } from '../../../utils/object';
import ResultsSection from './ResultsSection';

import areaFactory from '../../../model/area/area-factory';
import buildingFactory from '../../../model/building/building-factory';
import cameraFactory from '../../../model/camera/camera-factory';
import chastiaDeviceFactory from '../../../model/chastia-device/chastia-device-factory';
import chastiaSensorFactory from '../../../model/chastia-sensor/chastia-sensor-factory';
import controlledEntryFactory from '../../../model/controlled-entry/controlled-entry-factory';
import licensePlateFactory from '../../../model/license-plate/license-plate-factory';
import sipDoorFactory from '../../../model/sip-door/sip-door-factory';
import userFactory from '../../../model/user/user-factory';
import userGroupFactory from '../../../model/user-group/user-group-factory';

const SEARCH_DELAY = 500;

export default {
  name: 'dashboard-layout-search',
  components: {
    ResultsSection,
  },
  data() {
    return {
      areaFactory,
      buildingFactory,
      cameraFactory,
      chastiaSensorFactory,
      chastiaDeviceFactory,
      controlledEntryFactory,
      licensePlateFactory,
      sipDoorFactory,
      userFactory,
      userGroupFactory,

      q: '',
      lastSearchQuery: '',
      loading: false,
      visible: false,
      timeout: null,
      results: null,
      windowClickHandler: null,
      windowKeydownHandler: null,
    };
  },
  methods: {
    search() {
      clearTimeout(this.timeout);

      this.loading = true;
      this.timeout = setTimeout(() => this.sendRequest(this.q), SEARCH_DELAY);
    },
    sendRequest(q) {
      if (q.length >= 3) {
        connector
          .get(`/model/search?q=${encodeURIComponent(q)}`)
          .then(({ data }) => (this.results = data), (this.lastSearchQuery = q))
          .catch((e) => this.$modal.alert(e))
          .finally(() => (this.loading = false));
      } else {
        this.results = null;
        this.loading = false;
      }
    },
  },
  created() {
    /** @param {MouseEvent} e */
    this.windowClickHandler = (e) => {
      /** @type {HTMLElement} */
      let element = e.target;

      while (isObject(element)) {
        if (element.id === 'dashboard-layout-search') {
          return; // Do nothing
        }

        element = element.parentElement;
      }

      this.visible = false;
    };

    /** @param {KeyboardEvent} e */
    this.windowKeydownHandler = (e) => {
      switch (e.key) {
        case 'Escape':
          this.visible = false;
          this.$refs.input.blur();
          break;

        case '/':
          if (
            !this.focus &&
            !(document.activeElement instanceof HTMLInputElement) &&
            !(document.activeElement instanceof HTMLTextAreaElement)
          ) {
            e.preventDefault();
            this.visible = true;
            this.$refs.input.focus();
            return false;
          }
      }
    };

    window.addEventListener('click', this.windowClickHandler);
    window.addEventListener('keydown', this.windowKeydownHandler);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.windowClickHandler);
    window.removeEventListener('keydown', this.windowKeydownHandler);
  },
  watch: {
    q(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.visible = true;
        this.search();
      }
    },
  },
};
</script>

<style lang="scss">
#dashboard-layout-search {
  input[type='search'] {
    font-size: 1.1em;
  }

  .search-results {
    position: absolute;
    top: 59px;
    left: 20%;

    width: 60%;

    padding: 20px;
    border-radius: 0 0 4px 4px;

    background-color: white;
    box-shadow: 0 5px 10px 0 rgba(183, 192, 206, 0.2);
  }

  a {
    margin-bottom: 3px;

    color: inherit;

    transition: color 200ms ease-in-out;

    mark {
      transition: color 200ms ease-in-out;
    }

    &:hover,
    &:focus,
    &:focus mark,
    &:hover mark {
      color: #727cf5;
    }
  }

  svg.feather {
    width: 14px;
    height: 14px;

    margin-right: 6px;
    margin-top: 2px;
  }
}
</style>
