import Vue from 'vue';
import Vuex from 'vuex';

import modal from './modal/modal';
import licensePlateExport from './license-plate-export';
import changePassword from './change-password/change-password';
import resetPassword from './reset-password/reset-password';

import models from './models';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    modal,
    licensePlateExport,
    changePassword,
    resetPassword,

    models,
  },
});
