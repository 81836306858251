<template>
  <div class="cameras-tab">
    <h5 class="card-title">Cameras</h5>
    <p class="text-muted mb-1">The cameras in the building.</p>
    <ul class="text-muted mb-4">
      <li>The following table shows all cameras which are in any of the zones in the building.</li>
      <li>To add a camera to any of the zones in the building, go to the zone detail or the camera detail.</li>
    </ul>
    <phillip-table
      table="camera"
      hide-filters
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      empty-text="No cameras in the building."
      :filters="{ 'buildingId.eq': this.pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'building-detail-cameras-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
