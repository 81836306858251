<template>
  <div>
    <phillip-table
      table="user-group"
      hide-new-button
      hide-edit-button
      delete-button-label="Remove from whitelist"
      hide-filters
      empty-text="The whitelist is empty."
      :filters="{ [tableFilterName]: storage.pk(), 'owner.null': true }"
      :on-delete="removeFromWhitelist"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="user-group"
        placeholder="Add group to whitelist"
        v-model="userGroup"
        :filters="{ 'owner.null': true }"
      />
      <button @click="addToWhitelist" :disabled="userGroup === null" class="btn btn-sm btn-primary text-white ml-3">
        Whitelist group
      </button>
    </div>
  </div>
</template>

<script>
/**
 * A general whitelist form which can be used for a device of any type. The
 * requirements for the device are:
 *  - method `addWhitelist` must be implemented
 *  - method `deleteWhitelist` must be implemented
 *
 * Also the name of the filter, which returns all whitelisted groups
 * whitelisted by the device, must be specified
 */

import userGroupFactory from '../../model/user-group/user-group-factory';
import DataStorage from '../../model/abstract/data-storage';

export default {
  name: 'whitelist-tab',
  props: {
    storage: {
      type: DataStorage,
      required: true,
    },
    tableFilterName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userGroup: null,
    };
  },
  methods: {
    addToWhitelist() {
      if (this.userGroup !== null) {
        Promise.all([this.storage.deleteBlacklist(this.userGroup), this.storage.addWhitelist(this.userGroup)])
          .then(() => {
            this.userGroup = null;
            userGroupFactory.signalChange();
          })
          .catch((e) => this.$modal.alert(e));
      }
    },
    removeFromWhitelist(userGroupId) {
      this.$modal
        .confirm(`Delete group ${userGroupId} from whitelist?`)
        .then(() => {
          this.storage
            .deleteWhitelist(userGroupId)
            .then(() => userGroupFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
