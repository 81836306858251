<template>
  <div class="groups-tab">
    <h5 class="card-title">Groups</h5>
    <p class="text-muted mb-4">The groups with access to the zone.</p>
    <phillip-table
      table="user-group"
      hide-filters
      hide-new-button
      hide-edit-button
      delete-button-label="Deny access"
      empty-text="No group has access to the zone."
      :filters="{ 'areaAccess.eq': this.pk, 'owner.null': true }"
      :on-delete="removeAccess"
    />
    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 450px"
        select="userGroup"
        placeholder="Grant access to zone"
        v-model="userGroup"
        :filters="{ 'owner.null': true }"
      />
      <button @click="allowAccess" :disabled="userGroup === null" class="btn btn-sm btn-primary text-white ml-3">
        Allow access
      </button>
    </div>
  </div>
</template>

<script>
import userGroupFactory from '../../../model/user-group/user-group-factory';

export default {
  name: 'area-detail-groups-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      userGroup: null,
    };
  },
  methods: {
    allowAccess() {
      userGroupFactory
        .pkMock(this.userGroup)
        .addArea(this.pk)
        .then(() => userGroupFactory.signalChange())
        .catch((e) => this.$modal.alert(e));
    },
    removeAccess(userGroupId) {
      this.$modal
        .confirm(`Deny group ${userGroupId} access from the area?`)
        .then(() => {
          userGroupFactory
            .pkMock(userGroupId)
            .deleteArea(this.pk)
            .then(() => userGroupFactory.signalChange())
            .catch((e) => this.$modal.alert(e));
        })
        .catch(() => {}); // Do nothing
    },
  },
};
</script>
