<template>
  <div class="card">
    <div class="card-body">
      <template v-if="title">
        <header class="d-flex align-items-center mb-3">
          <h5 class="card-title m-0">{{ title }}</h5>
          <slot name="title-button"></slot>
        </header>
      </template>

      <slot name="subtitle">
        <template v-if="subtitle">
          <h6 class="card-subtitle mb-2 text-muted">{{ subtitle }}</h6>
        </template>
      </slot>

      <slot name="description">
        <template v-if="description">
          <p class="text-muted mb-4" v-html="description"></p>
        </template>
      </slot>

      <div class="card-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    title: String,
    subtitle: String,
    description: String,
  },
};
</script>

<style lang="scss">
.card {
  margin-bottom: var(--bs-gutter-x);
}
</style>
