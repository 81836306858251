import home from './home';
import Rfid from '../views/rfid/Rfid.vue';
import NewRfid from '../views/rfid/NewRfid.vue';
import RfidDetail from '../views/rfid/RfidDetail.vue';

const rfid = {
  path: '/rfid',
  name: 'RFID Cards',
  component: Rfid,
  meta: { parent: home },
};

const newRfid = {
  path: '/rfid/new',
  name: 'New RFID Card',
  component: NewRfid,
  meta: { parent: rfid },
};

const rfidDetail = {
  path: '/rfid/detail/:pk',
  name: 'RFID Card Detail',
  redirect: '/rfid/detail/:pk/rfid',
  component: RfidDetail,
  meta: { parent: rfid, noLink: true },
};

// Tabs
import RfidTab from '../views/rfid/tabs/RfidTab.vue';
import ChangelogTab from '../views/rfid/tabs/ChangelogTab.vue';

rfidDetail.children = [
  {
    path: '/rfid/detail/:pk/rfid',
    name: 'RFID Card',
    component: RfidTab,
    meta: { parent: rfidDetail },
  },
  {
    path: '/rfid/detail/:pk/changelog',
    name: 'Changelog',
    component: ChangelogTab,
    meta: { parent: rfidDetail },
  },
];

const rfidRoutes = [rfid, newRfid, rfidDetail];

export default rfidRoutes;
