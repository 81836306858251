<template>
  <div class="access-tokens-tab">
    <h5 class="card-title">Access Tokens</h5>
    <p class="text-muted mb-4">The valid, unused access tokens of the user.</p>
    <phillip-table
      table="access-token"
      hide-filters
      hide-edit-button
      disable-detail
      :on-new="newAccessToken"
      empty-text="The user has no access tokens."
      :filters="{ 'owner.eq': this.pk, valid: true }"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import accessTokenFactory from '../../../model/access-token/access-token-factory';

export default {
  name: 'user-detail-access-tokens-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    ...mapMutations({
      setArea: 'accessToken/form/setStorage',
    }),
    newAccessToken() {
      this.setArea(accessTokenFactory.blank({ owner: this.pk }));
      this.$modal.openForm('accessToken');
    },
  },
};
</script>
