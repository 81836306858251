<template>
  <div class="user-access-tab">
    <h5 class="card-title">Users</h5>
    <p class="text-muted">The list of users who can access the camera.</p>
    <ul class="text-muted mb-4">
      <li>All users who are part of at least one group which can access the camera also gain the camera access.</li>
    </ul>
    <phillip-table
      table="user"
      hide-filters
      hide-new-button
      hide-delete-button
      edit-button-label="Detail"
      empty-text="No one can access the camera."
      :filters="{ 'cameraAccess.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'camera-detail-user-access-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
