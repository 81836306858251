import home from './home';
import UserSipAccount from '../views/user-sip-account/UserSipAccount.vue';
// import NewUserSipAccount from '../views/user-sip-account/NewUserSipAccount.vue';
// import UserSipAccountDetail from '../views/user-sip-account/UserSipAccountDetail.vue';

const userSipAccount = {
  path: '/user-sip-account',
  name: 'Sip Accounts',
  component: UserSipAccount,
  meta: { parent: home },
};

// const newUserSipAccount = {
//   path: '/user-sip-account/new',
//   name: 'New Sip Account',
//   component: NewUserSipAccount,
//   meta: { parent: userSipAccount },
// };

// const userSipAccountDetail = {
//   path: '/user-sip-account/detail/:pk',
//   name: 'Sip Account Detail',
//   redirect: '/user-sip-account/detail/:pk/user-sip-account',
//   component: UserSipAccountDetail,
//   meta: { parent: userSipAccount, noLink: true },
// };

// Tabs
// import UserSipAccountTab from '../views/user-sip-account/tabs/UserSipAccountTab.vue';
// import LocationTab from '../views/user-sip-account/tabs/LocationTab.vue';
// import UserAccessTab from '../views/user-sip-account/tabs/UserAccessTab.vue';

// userSipAccountDetail.children = [
// {
//   path: '/user-sip-account/detail/:pk/user-sip-account',
//   name: 'Sip Account',
//   component: UserSipAccountTab,
//   meta: { parent: userSipAccountDetail },
// },
// {
//   path: '/user-sip-account/detail/:pk/location',
//   name: 'Zone',
//   component: LocationTab,
//   meta: { parent: userSipAccountDetail },
// },
// {
//   path: '/user-sip-account/detail/:pk/user-access',
//   name: 'User Access',
//   component: UserAccessTab,
//   meta: { parent: userSipAccountDetail },
// },
// ];

const userSipAccountRoutes = [
  userSipAccount,
  // newUserSipAccount,
  // userSipAccountDetail
];

export default userSipAccountRoutes;
