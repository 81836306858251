import Home from '../views/Home.vue';

const home = {
  path: '/home',
  name: 'Home',
  component: Home,
  redirect: '/user', // Temporary redirection - home is not completed
};

export default home;
