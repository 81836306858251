<template>
  <card title="All SIP Doors" description="The list of all SIP doors.">
    <new-sip-door-button slot="title-button" class="ml-3" />
    <phillip-table table="sip-door" hide-new-button />
  </card>
</template>

<script>
import NewSipDoorButton from '../../components/buttons/NewSipDoorButton';

export default {
  name: 'page-sip-door',
  components: {
    NewSipDoorButton,
  },
};
</script>
