<template>
  <button class="btn btn-sm btn-primary text-white" @click="_click">
    <span>New FAQ</span>
  </button>
</template>

<script>
export default {
  name: 'new-faq-button',
  props: {
    onClick: {
      type: Function,
      default: null,
    },
  },
  methods: {
    defaultClick() {
      this.$router.push('/faq/new');
    },
    _click() {
      (this.onClick || this.defaultClick)();
    },
  },
};
</script>
