<template>
  <div class="row w-100 mx-0 auth-page">
    <div class="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
      <h1 class="font-weight-bold mb-22 mt-2 tx-80 text-muted">404</h1>
      <h4 class="mb-2">Page Not Found</h4>
      <h6 class="text-muted mb-3 text-center">Oopps!! The page you were looking for doesn't exist.</h6>
      <router-link :to="session.jwt ? '/' : '/login'" class="btn btn-primary text-white"> Go back home </router-link>
    </div>
  </div>
</template>

<script>
import session from '../api/session';

export default {
  name: 'page-404',
  data() {
    return {
      session,
    };
  },
};
</script>
