import DataStorageFactory from '../abstract/data-storage-factory.js';
import SipDoorAction from './sip-door-action-class';

/** @extends DataStorageFactory<SipDoorAction> */
class SipDoorActionFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'actionName';
  getModelName = () => 'sip-door-action';
  getPrettyName = () => 'SIP Door Action';
}

const sipDoorActionFactory = new SipDoorActionFactory(SipDoorAction.prototype);

export default sipDoorActionFactory;
