<template>
  <div class="rfid-tab">
    <h5 class="card-title">RFID cards</h5>
    <p class="text-muted mb-4">
      The RFID cards of the user. RFID cards provide access to 2N controlled entries accessible by the user.
    </p>
    <phillip-table
      table="rfid"
      hide-filters
      hide-new-button
      edit-button-label="Detail"
      delete-button-label="Unassign"
      :on-delete="unassignRfid"
      empty-text="The user has no RFID cards."
      :filters="{ 'userId.eq': pk }"
      :exclude-columns="['userId']"
    />

    <div class="mt-3 d-flex">
      <phillip-select
        style="min-width: 550px"
        select="rfid"
        placeholder="Select RFID card"
        v-model="rfid"
        :filters="{ 'userId.neq': pk }"
      />
      <button @click="assignRfid" :disabled="rfid === null" class="btn btn-sm btn-primary text-white ml-3">
        Assign RFID card
      </button>
    </div>
  </div>
</template>

<script>
import rfidFactory from '../../../model/rfid/rfid-factory';
import userFactory from '../../../model/user/user-factory';

export default {
  name: 'user-detail-rfid-tab',
  data() {
    return {
      rfid: null,
    };
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  methods: {
    async assignRfid() {
      const rfid = await rfidFactory.pk(this.rfid);
      const user = rfid.userId ? await userFactory.pk(rfid.userId) : null;
      let shouldProceed = !user;

      if (!shouldProceed) {
        shouldProceed = confirm(
          `The RFID card is already assigned to user ${user.fullName}. Do you want to reassign it?`
        );
      }

      if (shouldProceed) {
        rfid.userId = this.pk;
        await rfid.save();
        this.rfid = null;
        rfidFactory.signalChange();
        userFactory.signalChange();
      }
    },
    async unassignRfid(rfidId) {
      const shouldProceed = confirm('Do you want to unassign the RFID card?');

      if (shouldProceed) {
        const rfid = await rfidFactory.pk(rfidId);
        rfid.userId = null;
        await rfid.save();
        rfidFactory.signalChange();
        userFactory.signalChange();
      }
    },
  },
};
</script>
