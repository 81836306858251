import DataStorageFactory from '../abstract/data-storage-factory.js';
import AppShortcut from './app-shortcut-class';

/** @extends DataStorageFactory<AppShortcut> */
class AppShortcutFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'name';
  getModelName = () => 'app-shortcut';
  getPrettyName = () => 'App Shortcut';
}

const appShortcutFactory = new AppShortcutFactory(AppShortcut.prototype);

export default appShortcutFactory;
