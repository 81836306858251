<template>
  <card
    class="short"
    title="New Camera"
    description="The general info about the camera. More settings will be available after the camera is created."
  >
    <phillip-form form="camera" />
  </card>
</template>

<script>
import { mapMutations } from 'vuex';
import cameraFactory from '../../model/camera/camera-factory';

export default {
  name: 'page-new-camera',
  methods: {
    ...mapMutations({
      setCamera: 'camera/form/setStorage',
    }),
  },
  created() {
    this.setCamera(cameraFactory.blank());
  },
  beforeDestroy() {
    this.setCamera(null);
  },
};
</script>
