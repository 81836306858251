import home from './home';
import ControlledEntry from '../views/controlled-entry/ControlledEntry.vue';
import NewControlledEntry from '../views/controlled-entry/NewControlledEntry.vue';
import ControlledEntryDetail from '../views/controlled-entry/ControlledEntryDetail.vue';

const controlledEntry = {
  path: '/controlled-entry',
  name: 'Controlled Entries',
  component: ControlledEntry,
  meta: { parent: home },
};

const newControlledEntry = {
  path: '/controlled-entry/new',
  name: 'New Controlled Entry',
  component: NewControlledEntry,
  meta: { parent: controlledEntry },
};

const controlledEntryDetail = {
  path: '/controlled-entry/detail/:pk',
  name: 'Controlled Entry Detail',
  redirect: '/controlled-entry/detail/:pk/controlled-entry',
  component: ControlledEntryDetail,
  meta: { parent: controlledEntry, noLink: true },
};

// Tabs
import ControlledEntryTab from '../views/controlled-entry/tabs/ControlledEntryTab.vue';
import LocationTab from '../views/controlled-entry/tabs/LocationTab.vue';
import WhitelistTab from '../views/controlled-entry/tabs/WhitelistTab.vue';
import BlacklistTab from '../views/controlled-entry/tabs/BlacklistTab.vue';
import UserAccessTab from '../views/controlled-entry/tabs/UserAccessTab.vue';
import UserGroupAccessTab from '../views/controlled-entry/tabs/UserGroupAccessTab.vue';
import LicensePlatesTab from '../views/controlled-entry/tabs/LicensePlatesTab.vue';

controlledEntryDetail.children = [
  {
    path: '/controlled-entry/detail/:pk/controlled-entry',
    name: 'Controlled Entry',
    component: ControlledEntryTab,
    meta: { parent: controlledEntryDetail },
  },
  {
    path: '/controlled-entry/detail/:pk/location',
    name: 'Zone',
    component: LocationTab,
    meta: { parent: controlledEntryDetail },
  },
  {
    path: '/controlled-entry/detail/:pk/whitelist',
    name: 'Whitelist',
    component: WhitelistTab,
    meta: { parent: controlledEntryDetail },
  },
  {
    path: '/controlled-entry/detail/:pk/blacklist',
    name: 'Blacklist',
    component: BlacklistTab,
    meta: { parent: controlledEntryDetail },
  },
  {
    path: '/controlled-entry/detail/:pk/user-access',
    name: 'Users',
    component: UserAccessTab,
    meta: { parent: controlledEntryDetail },
  },
  {
    path: '/controlled-entry/detail/:pk/user-group-access',
    name: 'Groups',
    component: UserGroupAccessTab,
    meta: { parent: controlledEntryDetail },
  },
  {
    path: '/controlled-entry/detail/:pk/license-plates',
    name: 'License Plates',
    component: LicensePlatesTab,
    meta: { parent: controlledEntryDetail },
  },
];

const cameraRoutes = [controlledEntry, newControlledEntry, controlledEntryDetail];

export default cameraRoutes;
