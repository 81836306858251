import DataStorageFactory from '../abstract/data-storage-factory.js';
import UserSipAccount from './user-sip-account-class';

/** @extends DataStorageFactory<UserSipAccount> */
class UserSipAccountFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'account';
  getModelName = () => 'user-sip-account';
  getPrettyName = () => 'User SIP Account';

  /**
   * Returns the SIP account of the specified user
   */
  async forUser(userId) {
    const data = await this.fetch({ 'userId.eq': userId });

    if (data.length >= 1) {
      return data[0];
    }

    return this.blank({ userId });
  }
}

const userSipAccountFactory = new UserSipAccountFactory(UserSipAccount.prototype);

export default userSipAccountFactory;
