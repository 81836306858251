<template>
  <div class="firebase-message-tab">
    <h5 class="card-title">Firebase Message</h5>
    <p class="text-muted mb-4">The general info about the Firebase message.</p>
    <phillip-form form="firebase-message" />
  </div>
</template>

<script>
import firebaseMessageFactory from '../../../model/firebase-message/firebase-message-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'page-new-firebase-message',
  methods: {
    ...mapMutations({
      setFirebaseMessage: 'firebaseMessage/form/setStorage',
    }),
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  created() {
    firebaseMessageFactory
      .pk(this.pk)
      .then((firebaseMessage) => this.setFirebaseMessage(firebaseMessage))
      .catch((e) => this.$modal.alert(e));
  },
  beforeDestroy() {
    this.setFirebaseMessage(null);
  },
};
</script>
