<template>
  <div class="sip-doors-tab">
    <h5 class="card-title">SIP Doors</h5>
    <p class="text-muted mb-1">The SIP doors in the building.</p>
    <ul class="text-muted mb-4">
      <li>The following table shows all SIP doors which are in any of the zones in the building.</li>
      <li>To add a SIP door to any of the zones in the building, go to the zone detail or the SIP door detail.</li>
    </ul>
    <phillip-table
      table="sip-door"
      hide-filters
      hide-new-button
      hide-delete-button
      empty-text="No SIP doors in the building."
      edit-button-label="Detail"
      :filters="{ 'buildingId.eq': pk }"
    />
  </div>
</template>

<script>
export default {
  name: 'building-detail-sip-doors-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
};
</script>
