<template>
  <div class="sip-account-tab">
    <h5 class="card-title">PIN Code</h5>
    <p class="text-muted mb-4">The user's PIN code for 2N SIP doors. Click on the asterisks to reveal the PIN code.</p>
    <spoiler style="margin-left: 16px; font-size: 4em" :value="pinCode" :max-length="5" />
  </div>
</template>

<script>
import userFactory from '../../../model/user/user-factory';

export default {
  name: 'user-detail-pin-code-tab',
  computed: {
    pk() {
      return this.$route.params.pk;
    },
  },
  data() {
    return {
      pinCode: 'Loading ...',
    };
  },
  created() {
    userFactory
      .pk(this.pk)
      .then((user) => (this.pinCode = user.pinCode))
      .catch((err) => this.$modal.alert(err));
  },
};
</script>
