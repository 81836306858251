import DataStorageFactory from '../abstract/data-storage-factory.js';
import RfidOwnerChangelog from './rfid-owner-changelog-class';

/** @extends DataStorageFactory<RfidOwnerChangelog> */
class RfidOwnerChangelogFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'id';
  getModelName = () => 'rfid-owner-changelog';
  getPrettyName = () => 'RFID Owner Changelog';
}

const rfidOwnerChangelogFactory = new RfidOwnerChangelogFactory(RfidOwnerChangelog.prototype);

export default rfidOwnerChangelogFactory;
