<template>
  <div>
    <p class="mb-3 mt--3 text-muted">The list of sensors which can be imported.</p>
    <phillip-table
      hide-new-button
      hide-delete-button
      disable-detail
      edit-button-label="Import"
      table="chastia-sensor"
      :exclude-columns="['active']"
      :filters="{ 'phillipImported.false': true }"
      :on-edit="importSensor"
    />
  </div>
</template>

<script>
import chastiaSensorFactory from '../../../../model/chastia-sensor/chastia-sensor-factory';

export default {
  name: 'chastia-sensor-list-import-tab',
  methods: {
    changeSensorImportState(sensorId, newState) {
      const action = newState ? 'Import' : 'Delete';
      const prompt = `${action} sensor with ID ${sensorId}?`;

      this.$modal
        .confirm(prompt)
        .then(() => {
          chastiaSensorFactory
            .blank({ id: sensorId, phillipImported: newState })
            .save()
            .catch((err) => this.$modal.alert(err))
            .finally(() => chastiaSensorFactory.signalChange());
        })
        .catch(() => {}); // Do nothing
    },
    importSensor(sensorId) {
      return this.changeSensorImportState(sensorId, true);
    },
  },
};
</script>
