<template>
  <div class="row w-100 mx-0 auth-page page-reset-password">
    <div class="col-md-6 col-xl-4 mx-auto">
      <div class="card mb">
        <div class="row">
          <div class="col-md-12 pl-md-0">
            <div class="auth-form-wrapper px-4 py-5">
              <h5 class="font-weight-normal mb-4">RESET PASSWORD</h5>
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-reset-password',
};
</script>
